.categories-1FD1Z {
    padding: 10px;
    padding-bottom: 6px;
    background: var(--tertiary);
  }
.categories-1FD1Z .select-1YN7I {
      margin: 2px;
    }
.categories-1FD1Z .select-1YN7I input {
        padding: 3px 10px;
      }
