div > div.modal-3pc96 img {
      width: 100%;
    }
  .radio-group-2rHcI > div {
    width: 100%;
  }
  .radio-group-2rHcI > div label {
      padding-bottom: 5px;
    }
  .radio-group-2rHcI > div label:last-child {
        padding-bottom: 0;
      }
  .reason-textarea-uk6v- {
    width: 100%;
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .reason-textarea-uk6v- > textarea {
      font-size: 14px;
    }
  .reason-container-1HkKb {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .reason-container-1HkKb > p {
      margin-left: 10px;
    }
  .mandatory-ia-wr::after {
    content: "*";
    color: var(--danger);
    margin-left: 2px;
  }
