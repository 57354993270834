.container-1P43H {
    display: flex;
    margin: 20px 0;
  }
div.profile-photo-E_kIz {
    margin-top: 5px;
    background-color: var(--steel2);
    flex-shrink: 0;
  }
.delete-option-2Ma8W {
    align-items: center;
    display: flex;
    margin-left: 15px;
    margin-top: 1px;
    outline: none;
    background-color: var(--steel2);
    padding: 5px 10px;
    color: var(--aluminum2);
    border: 1px solid;
  }
.delete-option-2Ma8W .delete-mObM- {
      margin: 5px;
      margin-left: 15px;
    }
.delete-option-2Ma8W .text-3an68 {
      margin: 5px;
      flex-grow: 1;
    }
.separator-137Ih {
    border-left: 1px solid black;
    margin: 0 15px;
    border-color: var(--aluminum4);
  }
.created-by-ukcjK {
    display: flex;
    color: var(--steel2);
    margin: 0 0 10px;
  }
.created-by-ukcjK .name-Vqx-D {
      font-size: 16px;
      color: var(--steel4);
    }
.title-3I9SR {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: var(--steel2);
    font-weight: 600;
    align-self: flex-end;
  }
.box-1XtOQ {
    flex-grow: 1;
    margin-left: 15px;
    padding: 10px 15px;
    border: 1px solid var(--aluminum3);
    border-left: 1px solid var(--aluminum5);
    box-shadow: 2px 2px 4px var(--aluminum2);
    position: relative;

    font-family: var(--lume-g-font-family);

    font-style: normal;

    font-weight: 200;
  }
.box-1XtOQ::before {
      display: table;
      content: "";
      position: absolute;
      left: -6px;
      top: 13px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid var(--aluminum5);
    }
.box-1XtOQ .header-qQ7ed {
      display: flex;
      align-items: center;
    }
@media (max-width: 767px) {
.box-1XtOQ .header-qQ7ed {
        flex-wrap: wrap
    }
      }
.box-1XtOQ .header-qQ7ed .rating-2Gojt {
        margin-right: 10px;
        white-space: nowrap;
        font-size: 21px;
      }
.box-1XtOQ .text-3an68 {
      overflow-wrap: break-word;
      word-break: break-all;
      word-break: break-word;

      margin: 10px 0;
      white-space: pre-wrap;
    }
.review-box-21eqj {
    flex-grow: 1;
  }
.review-actions-1UCOY {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
.review-actions-1UCOY .action-1difn {
      display: inline-flex;
      font-family: inherit;
      font-size: 14px;
      padding: 0;
      border: none;
      cursor: pointer;
      fill: var(--link-color);
      color: var(--link-color);
      text-decoration: none;
      background-color: transparent;
    }
.review-actions-1UCOY .action-1difn:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
    background-color: transparent;
    border: none;
  }
.review-actions-1UCOY .action-1difn {

      margin-left: 15px;
      color: var(--steel1);
      display: flex;
      align-items: center;
}
.review-actions-1UCOY .action-1difn span {
        display: flex;
        align-items: center;
      }
.review-actions-1UCOY .action-1difn svg {
        margin-right: 2px;
        flex-direction: row;
        fill: var(--steel1);
      }
.review-actions-1UCOY .action-1difn:hover {
        color: var(--core-blue2);
        border-color: var(--core-blue2);
      }
.review-actions-1UCOY .action-1difn:hover svg {
          fill: var(--core-blue2);
        }
.review-actions-1UCOY .action-1difn.focus-visible {
        color: var(--core-blue2);
        border-color: var(--core-blue2);
      }
.review-actions-1UCOY .action-1difn.focus-visible svg {
          fill: var(--core-blue2);
        }
div.box-1XtOQ input,
    div.box-1XtOQ textarea {
      font-size: 14px;
    }
div.box-1XtOQ textarea {
      margin: 0;
      padding: 9.5px 10px;
    }
.expandable-3XBVr .rate-label-2YnFW {
      display: flex;
    }
.expandable-3XBVr .rate-label-2YnFW > label {
        margin-top: 0;
      }
.expandable-3XBVr .header-qQ7ed {
      display: flex;
    }
@media (max-width: 767px) {
.expandable-3XBVr .header-qQ7ed {
        flex-wrap: wrap
    }
      }
.expandable-3XBVr .header-qQ7ed .header-button-2266I {
        display: flex;
        flex-grow: 1;
        align-items: center;
        cursor: pointer;
      }
.expandable-3XBVr .header-qQ7ed .rating-2Gojt {
        margin-right: 10px;
        font-size: 19px;
      }
.expandable-3XBVr .header-qQ7ed .rate-message-2MAX7 {
        overflow-wrap: break-word;
        word-break: break-all;
        word-break: break-word;

        margin-right: 5px;
        font-size: 0.9em;
        font-style: italic;
        color: var(--steel2);
      }
.actions-1dbYq {
    margin-top: 15px;
    text-align: right;
  }
.actions-1dbYq button {
      margin-left: 10px;
    }
.container-1P43H {
    flex-grow: 1;
  }
.label-2uEbx {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 200;

    font-size: 19px;
    line-height: 1.5;
    margin: 10px 0;
    display: block;
  }
