a.create-asset-23bTf {
    display: flex;
    color: var(--tertiary);
    align-items: center;
  }
@media (max-width: 767px) {
a.create-asset-23bTf {
      margin-right: 0;
  }
    }
.js-focus-visible a.create-asset-23bTf.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.publish-2s3l7 {
    display: flex;
    justify-content: center;
  }
.publish-2s3l7 > a {
      margin: var(--padding-lg);
    }
