.fields--QmvV {
    margin-bottom: 15px;
  }
  .radio-group-3FoLj {
    display: flex;
    margin-bottom: 15px;
  }
  .radio-group-3FoLj .search-type-1W3G_ {
      margin-top: 10px;
    }
  .radio-group-3FoLj .type-radio-group-3Kpi_ {
      align-content: space-between;
      margin-left: 70px;
    }
  .radio-group-3FoLj .type-radio-group-3Kpi_ label {
        padding-bottom: 5px;
      }
  .radio-group-3FoLj .type-radio-group-3Kpi_ label:last-child {
          padding-bottom: 0;
        }
