.gdpr-xsi1- {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    padding: 20px 80px;
    background: rgba(0, 0, 0, 0.7);
  }
@media (max-width: 991px) {
.gdpr-xsi1- {
      padding: 20px
  }
    }
.copy-container-emhOr {
    max-width: 1199px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
.copy-container-emhOr p {
      color: var(--tertiary);
      font-size: 15px;
    }
.close-59-Cp {
    color: var(--tertiary);
    font-size: 35px;
    cursor: pointer;
    margin-left: 40px;
    display: flex;
    align-items: center;
  }
@media (max-width: 991px) {
.close-59-Cp {
      margin-left: 20px
  }
    }
.close-59-Cp:hover,
    .close-59-Cp:focus {
      color: var(--core-blue2);
    }
