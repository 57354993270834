.saved-3HE41 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
.saved-3HE41 .text-field-3pQSl {
      display: flex;
      flex-grow: 1;
    }
.saved-3HE41 .text-field-3pQSl input[type="text"] {
        padding: 6px 10px 6px 30px;
        border-left-width: 4px;
        font-size: 13px;
        line-height: 0;
      }
.saved-3HE41 .error-item-message-127cF {
      margin: 5px;
      font-size: 12px;
    }
.saved-3HE41 .saved-link-3WZtZ {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      width: calc(100% - 4px);
      border-left: 4px solid transparent;
      padding: 6px 20px;
      margin-left: 4px;
      color: var(--text-color);
    }
.saved-3HE41 .saved-link-3WZtZ:hover {
        border-left: 4px solid var(--robust-blue1);
      }
.saved-3HE41 .saved-link-3WZtZ:hover .label-1sM7m {
          color: var(--steel5);
        }
.saved-3HE41 .saved-link-3WZtZ:focus,
      .saved-3HE41 .saved-link-3WZtZ.active-3_whY {
        border-left: 4px solid var(--robust-blue2);
      }
.saved-3HE41 .saved-link-3WZtZ:focus .label-1sM7m, .saved-3HE41 .saved-link-3WZtZ.active-3_whY .label-1sM7m {
          color: var(--steel5);
        }
.saved-3HE41 .saved-link-3WZtZ.active-3_whY {
        background-color: var(--aluminum2);
      }
.saved-3HE41 .saved-link-3WZtZ .label-1sM7m {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 13px;
      }
.saved-3HE41 .saved-link-3WZtZ .content-shown-true .icon-1ZtaV svg {
              fill: var(--primary);
            }
.saved-3HE41 .trigger-container-1CHkn {
      position: absolute;
      right: 0;
      padding: 0 5px;
    }
.saved-3HE41 .action-popover-1VXCo {
      display: flex;
    }
.saved-3HE41 .action-popover-1VXCo > div {
        outline: none;
      }
.saved-3HE41 .action-popover-1VXCo > div:focus svg {
            fill: var(--core-blue2);
          }
.saved-3HE41 .action-popover-1VXCo .icon-1ZtaV {
        align-self: center;
        display: flex;
        transform: rotate(90deg);
      }
.saved-3HE41 .action-popover-1VXCo .icon-1ZtaV svg {
          fill: var(--steel3);
        }
.saved-3HE41 .action-popover-1VXCo .icon-1ZtaV:hover {
          cursor: pointer;
          color: var(--core-blue2);
        }
.saved-3HE41 .action-popover-1VXCo .icon-1ZtaV:hover svg {
            fill: var(--core-blue2);
          }
.js-focus-visible .menu-1EEEv.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.menu-1EEEv {

    border-top: 2px solid var(--aluminum4);
    border-bottom: 2px solid var(--aluminum4);
    box-shadow: 2px 2px 4px var(--aluminum3);
    min-width: 100px;
}
.js-focus-visible .menu-1EEEv .menu-item-ho8vJ.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.menu-1EEEv .menu-item-ho8vJ {

      font-size: 12px;
      padding: 8px 10px;
}
