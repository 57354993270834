.container-2xlsN {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 400;

    color: var(--tertiary);
    position: relative;
  }
.container-2xlsN > div > div > a.cookie-preferences-2kqB4 {
      margin-right: 25px;
      border: 0;
    }
.bumper-1Esx_ {
    min-height: var(--navbar-height);
  }
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
.bar-customize-2_B1e {
    max-width: calc(100% - 32%);
    width: calc(100% - 32%);
  }
  }
/* Large */
@media (min-width: 1200px) and (max-width: 1599px) {
.bar-customize-2_B1e {
    max-width: calc(100% - 30%);
    width: calc(100% - 30%);
  }
  }
/* Extra Large */
@media (min-width: 1600px) and (max-width: 2299px) {
.bar-customize-2_B1e {
    max-width: calc(100% - 27%);
    width: calc(100% - 27%);
  }
  }
/* Extra Extra Large */
@media (min-width: 2300px) {
.bar-customize-2_B1e {
    max-width: calc(100% - 25%);
    width: calc(100% - 25%);
  }
  }
.bar-30QZu {
    min-height: var(--navbar-height);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1030; /* copied from anypoint-navbar */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
.left-3e7g9 {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
.left-3e7g9 .toggle-zEjgt {
      display: none;
    }
@media (max-width: 991px) {
.left-3e7g9 {
      margin-left: 0
  }

      .left-3e7g9 .toggle-zEjgt {
        display: flex;
        width: 30px;
        height: 30px;
      }
    }
.left-3e7g9 .logo-3-_9G {
      display: flex;
      align-items: center;
      margin-left: 15px;
      align-self: flex-start;
      height: var(--navbar-height);
      position: relative;
    }
.left-3e7g9 .logo-3-_9G .anypoint-icon {
          fill: var(--core-blue2);
        }
.left-3e7g9 .logo-3-_9G span {
        color: var(--tertiary);
      }
.left-3e7g9 .logo-3-_9G img {
        max-height: calc(100% - 10px);
        max-width: 200px;
        display: block;
        margin: 0 10px;
      }
.left-3e7g9 .logo-3-_9G:focus {
        /* stylelint-disable-next-line declaration-no-important */
        outline: var(--core-blue3) auto 5px !important;
      }
.right-2I-nM {
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
  }
.right-2I-nM > span {
      margin: 0 15px;
    }
@media (max-width: 991px) {
.right-2I-nM {
      margin-right: 0
  }
    }
