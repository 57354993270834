.saved-search-1qaWc {
    display: flex;
    flex-direction: column;
    margin: 15px 0 10px;
  }
.saved-search-1qaWc h6.header-jHO0g {
      margin: 0 10px 5px 20px;
      color: var(--robust-blue3);
      font-size: 13px;
    }
.show-more-12AGe {
    display: inline-flex;
    font-family: inherit;
    font-size: 14px;
    padding: 0;
    border: none;
    cursor: pointer;
    fill: var(--link-color);
    color: var(--link-color);
    text-decoration: none;
    background-color: transparent;
  }
.show-more-12AGe:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
    background-color: transparent;
    border: none;
  }
.show-more-12AGe {

    display: flex;
    font-size: 13px;
    margin: 5px 15px;
    justify-content: flex-end;
}
.show-more-12AGe:focus {
      text-decoration: underline;
    }
.spinner-1PAtm {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    justify-content: center;
  }
