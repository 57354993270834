.button-3N2cO {
    display: inline-flex;
    font-family: inherit;
    font-size: 14px;
    padding: 0;
    border: none;
    cursor: pointer;
    fill: var(--link-color);
    color: var(--link-color);
    text-decoration: none;
    background-color: transparent;
  }
.button-3N2cO:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
    background-color: transparent;
    border: none;
  }
.button-3N2cO {
    margin-right: 4px;
    margin-bottom: 4px;
    max-width: 100%;
}
.button-3N2cO .pill-span-1wHOl {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
.button-3N2cO {

    align-items: center;
    padding: 2px;
    color: var(--steel2);
}
.button-3N2cO > .anypoint-icon {
      display: flex;
      align-items: center;
    }
.button-3N2cO > .anypoint-icon svg {
        fill: var(--steel2);
        margin-right: 5px;
      }
.button-3N2cO:hover,
    .button-3N2cO.focus-visible {
      color: var(--core-blue3);
    }
.button-3N2cO:hover svg, .button-3N2cO.focus-visible svg {
        fill: var(--core-blue3);
      }
