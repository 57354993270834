.container-3GMcV {
    background-color: var(--aluminum2);
  }
.container-3GMcV .actions-215ZJ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary);
    height: 60px;
    padding: 0 4px;
  }
.container-3GMcV .actions-215ZJ .action-Zpe4M {
    display: flex;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    padding: 5px 10px;
    color: var(--aluminum1);
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
  }
.container-3GMcV .actions-215ZJ .action-Zpe4M svg {
    vertical-align: middle;
    fill: var(--aluminum1);
  }
.container-3GMcV .actions-215ZJ .action-Zpe4M:hover,
  .container-3GMcV .actions-215ZJ .action-Zpe4M.focus-visible {
    background-color: var(--robust-blue4);
    color: var(--aluminum1);
    border-radius: 4px;
  }
.container-3GMcV .actions-215ZJ .action-Zpe4M:hover svg, .container-3GMcV .actions-215ZJ .action-Zpe4M.focus-visible svg {
      fill: var(--aluminum1);
    }
.container-3GMcV .actions-215ZJ .action-Zpe4M:focus {
    outline: none;
  }
.container-3GMcV .actions-215ZJ .action-Zpe4M .label-FmROJ {
    margin-left: 5px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
  }
.container-3GMcV .actions-215ZJ .action-Zpe4M[disabled],
  .container-3GMcV .actions-215ZJ .action-Zpe4M.disabled-1RQTa {
    pointer-events: none;
    color: var(--aluminum3);
    opacity: 0.5;
  }
.container-3GMcV .actions-215ZJ .action-Zpe4M[disabled] svg, .container-3GMcV .actions-215ZJ .action-Zpe4M.disabled-1RQTa svg {
      fill: var(--aluminum3);
    }
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
.container-3GMcV {
    max-width: 32%;
    width: 32%
  }
  }
/* Large */
@media (min-width: 1200px) and (max-width: 1599px) {
.container-3GMcV {
    max-width: 30%;
    width: 30%
  }
  }
/* Extra Large */
@media (min-width: 1600px) and (max-width: 2299px) {
.container-3GMcV {
    max-width: 27%;
    width: 27%
  }
  }
/* Extra Extra Large */
@media (min-width: 2300px) {
.container-3GMcV {
    max-width: 25%;
    width: 25%
  }
  }
@media (min-width: 992px) {
.container-3GMcV {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh
  }
  }
@media (min-width: 992px) {
.container-3GMcV {
      box-shadow: inset 1px 0 0 0 var(--aluminum4), 0 2px 4px 0 rgba(0, 0, 0, 0.5)
  }
    }
.container-3GMcV .color-picker-2BWZf input[type="color"] {
        padding: 0 5px;
        width: 150px;
        height: 40px;
      }
.container-3GMcV .color-picker-2BWZf + .color-picker-2BWZf {
        margin-top: 10px;
      }
.container-3GMcV .label-FmROJ {
      font-family: var(--lume-g-font-family);
      font-style: normal;
      font-weight: 200;

      font-size: 19px;
      line-height: 1.5;
      margin: 10px 0;
      display: block;
    }
.container-3GMcV .main-32SeI {
      padding: 0 20px;
      max-height: calc(100% - 60px);
      height: 100%;
      overflow: auto;
    }
.container-3GMcV .main-32SeI .draft-msg-29hrT {
        margin-top: 20px;
        padding: 15px 20px;
        background-color: var(--yellow3);
        color: var(--tertiary);
      }
.container-3GMcV .main-32SeI .draft-msg-29hrT .draft-msg-icon-3FmAi {
          fill: var(--tertiary);
          margin-right: 8px;
          vertical-align: middle;
        }
.container-3GMcV .main-32SeI .custom-pages-KGaB5 {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
.container-3GMcV .main-32SeI .custom-pages-KGaB5 a {
          font-size: 14px;
          display: flex;
          align-items: center;
          color: var(--link-color);
          fill: var(--link-color);
        }
.container-3GMcV .main-32SeI .custom-pages-KGaB5 a:hover {
            color: var(--link-hover-color);
            fill: var(--link-hover-color);
          }
.container-3GMcV .main-32SeI .custom-pages-KGaB5 a .icon-1k3dF {
            display: flex;
            align-items: center;
            margin-right: 2px;
          }
.container-3GMcV .main-32SeI .columns-1t4VL {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
.container-3GMcV .main-32SeI .columns-1t4VL > div {
          display: inline-block;
          padding-right: 10px;
        }
.container-3GMcV .main-32SeI .columns-1t4VL > div:last-child {
            padding-right: 0;
          }
.container-3GMcV .main-32SeI .pages-tree-3h2X7 {
        margin-left: -20px;
        margin-right: -20px;
      }
.container-3GMcV .main-32SeI .pages-tree-3h2X7 a {
          padding-left: 10px;
        }
.container-3GMcV .main-32SeI .spinner-2WfVd {
        display: flex;
        justify-content: center;
        padding: 13em 0;
      }
