.container-Ig_0n {
    color: var(--text-color);
  }

    .container-Ig_0n .emphasis-1tdy5 {
      font-weight: bold;
    }

    .container-Ig_0n table th {
        font-weight: 600;
        border-bottom: 1px solid var(--aluminum3);
      }

    .container-Ig_0n table tr .open-icon-CZudc {
          visibility: hidden;
          margin-left: 5px;
        }

    .container-Ig_0n table tr:hover a {
            color: var(--primary);
          }

    .container-Ig_0n table tr:hover .open-icon-CZudc {
            visibility: visible;
          }

    .container-Ig_0n table tr:last-of-type td {
          border-bottom: 0;
        }
