.loading-2TQAp {
    color: var(--aluminum4);
    text-align: center;
  }

    .loading-2TQAp.small-a__T9 {
      margin: 10px 0;
      font-size: 13px;
    }

    .loading-2TQAp.medium-3soQD {
      margin: 40px 0;
      font-size: 18px;
    }

    .loading-2TQAp span {
      vertical-align: middle;
    }

    .loading-2TQAp .spinner-4vMyG {
      display: inline-block;
      margin-right: 15px;
      vertical-align: middle;
    }
