:root {
    --anypoint-navbar-s-color-background: #fff;
  }
.public-portal-2GXNP {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
@media (max-width: 991px) {
.public-portal-2GXNP {
      flex-direction: column
  }
    }
.public-portal-2GXNP.no-scroll-3zCns {
      overflow: hidden;
    }
.container-2zU-J {
    width: 100%;
    display: flex;
    flex-grow: 1;
  }
@media (max-width: 991px) {
.container-2zU-J {
      flex-direction: column
  }
    }
