.button-3dt6w {
    line-height: 1.6;
    z-index: 1;
  }
.button-3dt6w > span.button-children {
      display: flex;
      align-items: center;
    }
@media (max-width: 767px) {
.button-3dt6w .edit-icon-39DAb {
        /* stylelint-disable-next-line declaration-no-important */
        margin-right: 0 !important
    }
      }
.button-3dt6w .label-12dcM {
      padding-left: 8px;
      font-size: 15px;
    }
@media (max-width: 767px) {
.button-3dt6w .label-12dcM {
        display: none
    }
      }
