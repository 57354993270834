.footer-lgivX {
    position: fixed;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 40px;
    background: var(--steel1);
  }
.footer-lgivX > a {
      color: var(--tertiary);
    }
.footer-lgivX > a::after {
        content: '';
        background-image: url("https://www.mulesoft.com/sites/default/files/cmm_files/new-graphics-WES_updated012022.svgz");
        width: 26px;
        height: 16px;
        position: absolute;
        background-size: 400px;
        background-position: 51px 305px;
        margin-left: 8px;
      }
