/*
 * Anypoint Styles (anypoint-ui mono-repo) v5.0.32
 * Deployed by Valkyr
 */
:root {
  --primary: var(--core-blue3);
  --secondary: var(--robust-blue3);
  --tertiary: var(--lume-g-color-neutral-100);
  --success: var(--future-green3);
  --danger: var(--red3);

  --aluminum1: var(--lume-g-color-neutral-95);
  --aluminum2: var(--lume-g-color-neutral-90);
  --aluminum3: var(--lume-g-color-neutral-80);
  --aluminum4: var(--lume-g-color-neutral-70);
  --aluminum5: var(--lume-g-color-neutral-65);

  --core-blue1: var(--lume-g-color-blue-95);
  --core-blue2: var(--lume-g-color-blue-65);
  --core-blue3: var(--lume-g-color-blue-50);
  --core-blue4: var(--lume-g-color-blue-30);
  --core-blue5: var(--lume-g-color-blue-20);

  --future-green1: #dcfdd0;
  --future-green2: var(--lume-g-color-green-60);
  --future-green3: var(--lume-g-color-green-50);
  --future-green4: #22683e;
  --future-green5: var(--lume-g-color-green-30);

  --red1: var(--lume-g-color-red-95);
  --red2: var(--lume-g-color-red-50);
  --red3: var(--lume-g-color-red-40);
  --red4: var(--lume-g-color-red-30);
  --red5: var(--lume-g-color-red-20);

  --robust-blue1: #a1b1b8;
  --robust-blue2: #6b8a99;
  --robust-blue3: #506773;
  --robust-blue4: #32444d;
  --robust-blue5: #272f33;

  --steel1: var(--lume-g-color-neutral-60);
  --steel2: var(--lume-g-color-neutral-50);
  --steel3: var(--lume-g-color-neutral-40);
  --steel4: var(--lume-g-color-neutral-30);
  --steel5: #0b0b0b;

  --yellow1: #fef3d9;
  --yellow2: #ffe3a2;
  --yellow3: var(--lume-g-color-yellow-80);
  --yellow4: var(--lume-g-color-yellow-70);
  --yellow5: var(--lume-g-color-yellow-60);

  --indigo3: #5e66f9;
  --navy3: #178bea;
  --teal3: #00b5d1;
  --viridian3: #00b49d;
  --violet3: #9a63f9;
}
@keyframes blink {
  0% {
    box-shadow: 0;
  }

  50% {
    box-shadow: 0 0 8px var(--core-blue3);
  }

  100% {
    box-shadow: 0;
  }
}
:root {
    --font-family: var(--lume-g-font-family);
    --navbar-height: var(--anypoint-navbar-c-navbar-sizing-height , 84px);
  }
html {
    font-size: 14px;
  }
#react-navbar-view {
    height: var(--navbar-height);
    background-color: var(--anypoint-navbar-s-color-background, var(--tertiary));
  }
body {
    margin: 0;
    padding: 0;
  }
body .apic-container {
      --api-navigation-list-section-font-size: 14px;
      --api-navigation-list-section-font-weight: 400;
      --api-navigation-endpoint-font-size: 14px;
      --api-navigation-endpoint-font-weight: 400;
      --api-navigation-section-title-padding: 0px 15px 1px 19px;
      --api-navigation-list-item-summary-padding: 7px 15px 10px 19px;
      --api-navigation-list-item-padding: 5px 15px 7px 19px;
      --api-navigation-list-item-selected-weight: 400;
      --api-navigation-list-item-selected-background-color: var(--aluminum3);

      /* Documentation elements */
      --arc-font-display1-font-size: 28px;
      --arc-font-display1-font-weight: 700;
      --arc-font-display1-line-height: 42px;
      --arc-font-title-font-size: 25px;
      --arc-font-title-font-weight: 700;
      --arc-font-title-line-height: 1.5;
      --arc-font-subhead-font-size: 22px;
      --arc-font-subhead-font-weight: 700;
      --arc-font-subhead-color: var(--steel3);
      --arc-font-h3-margin: 10px 0;

      /* Documentation */
      --arc-font-headline-font-size: 28px;
      --arc-font-headline-font-weight: 700;
      --arc-font-headline-line-height: 1.5;
      --api-method-documentation-h3-font-color: var(--steel3);
      --api-method-documentation-section-padding: 10px 0 0 0;
      --api-method-documentation-description-color: var(--steel3);
      --api-method-documentation-section-background-color: transparent;

      color: var(--text-color);
    }
.expand {
    height: 100%;
  }
.expand > body,
    .expand .react-containers {
      height: auto;
    }
.expand #react-component-view {
      display: flex;
      flex-direction: column;
      height: calc(100% - var(--navbar-height));
      min-height: calc(100vh - var(--navbar-height));
    }
.expand .no-navbar #react-component-view {
        height: 100%;
      }
.prevent-scroll {
    overflow: hidden;
  }
.force-outline {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.anypoint-styles pre > code {
    padding: 0;
  }
.js-focus-visible a.focus-visible, .js-focus-visible button.focus-visible, .js-focus-visible [role="listbox"].focus-visible, .js-focus-visible [role="button"].focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.emphasize {
    animation-duration: 0.8s;
    animation-name: blink;
    animation-timing-function: ease-in-out;
  }
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header > .ot-pc-logo {
    margin-left: 20px;
  }
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header > .ot-title-cntr #ot-pc-title {
    margin-left: 100px;
    font-family: inherit;
  }
