.page-name-1fP0G {
    margin: 10px 0;
    margin-right: 20px;
    flex-grow: 1;
  }
.page-name-1fP0G .page-name-input-1Pxxa input {
        font-family: var(--lume-g-font-family);
        font-style: normal;
        font-weight: 400;

        width: 100%;
        padding: 4px 10px;
        font-size: 20px;
      }
