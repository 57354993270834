.view-switcher-3GU_j {

    margin: 5px;
    display: flex;
    flex-flow: row nowrap;
  }
@media (max-width: 767px) {
.view-switcher-3GU_j {
      display: none
  }
    }
.view-switcher-3GU_j .icon-wrapper-APxON {
      border-right: 1px solid var(--aluminum3);
      align-self: center;
    }
.view-switcher-3GU_j .icon-wrapper-APxON:last-child {
        border: none;
      }
.view-switcher-3GU_j .icon-wrapper-APxON a {
        padding: 8px;
        fill: var(--steel2);
      }
.view-switcher-3GU_j .icon-wrapper-APxON a:hover {
          fill: var(--robust-blue2);
        }
.view-switcher-3GU_j .icon-wrapper-APxON a:focus {
          border-bottom: 3px solid var(--aluminum4);
        }
.view-switcher-3GU_j .active-38ULM,
    .view-switcher-3GU_j .active-38ULM:focus {
      border-bottom: 3px solid var(--robust-blue2);
    }
