.reviews-1Fnlr {
    margin-bottom: 20px;
  }

    .reviews-1Fnlr hr {
      border: 1px solid var(--aluminum3);
    }

    .reviews-1Fnlr .empty-3AWTc {
      margin-top: 20px;
      color: var(--aluminum5);
      font-style: italic;
    }
