.search-3mRVx {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 15px;
  }
.search-3mRVx .perspective-2oprY {
      background-color: var(--robust-blue2);
      color: var(--tertiary);
      padding: 12px;
      margin: 10px;
      white-space: nowrap;
    }
@media (max-width: 767px) {
.search-3mRVx .perspective-2oprY {
        margin-right: 0
    }
      }
.search-3mRVx .filter-3E9Nx {
      padding: 10px 10px 10px 0;
      display: flex;
      position: relative;
    }
.search-3mRVx .filter-3E9Nx .new-tag-2ptzy {
        display: inline;
        line-height: 20px;
        vertical-align: middle;
        padding: 1px 6px;
        font-size: 10px;
        border-radius: 3px;
        color: var(--tertiary);
        background-color: var(--core-blue3);
        margin-left: 10px;
      }
