.labels-container-2z3E3 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 6px;
    background: var(--tertiary);
  }
.labels-container-2z3E3 .textfield-2GqPC {
      display: inline-flex;
      margin-bottom: 4px;
    }
.labels-container-2z3E3 .textfield-2GqPC input {
        width: 100px;
        padding: 2px 10px;
        text-transform: lowercase;
      }
.labels-container-2z3E3 .error-1-XGA input {
      border-color: var(--danger);
    }
.labels-container-2z3E3 .error-message-3ObYP {
      color: var(--danger);
    }
