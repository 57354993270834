div.draft-header-3sXE0 {
    width: calc(100% + (20px * 2));
    margin: 0 -20px;
    height: 60px;
    color: var(--tertiary);
    background-color: var(--robust-blue3);
    line-height: 60px;
    display: flex;
  }
div.draft-header-3sXE0 .text-1WkFB {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      flex-grow: 1;
    }
.js-focus-visible div.draft-header-3sXE0 .text-1WkFB.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
div.draft-header-3sXE0 svg {
      vertical-align: middle;
      fill: var(--tertiary);
      margin: 0 10px;
    }
.js-focus-visible div.draft-header-3sXE0 .button-2Hb9F.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
div.draft-header-3sXE0 .button-2Hb9F {

      background-color: var(--tertiary);
      line-height: normal;
      line-height: initial;
}
div.draft-header-3sXE0 .button-2Hb9F + .button-2Hb9F {
        margin-left: 10px;
      }
div.draft-header-3sXE0 .actions-qxlrk {
      margin: 0 10px;
      white-space: nowrap;
    }
div.draft-header-3sXE0 .actions-qxlrk a,
      div.draft-header-3sXE0 .actions-qxlrk .button-2Hb9F {
        padding: 8px 15px;
      }
div.draft-header-3sXE0 .edit-action-3c_Rn {
      margin: 0 10px;
      color: var(--tertiary);
    }
div.draft-header-3sXE0 .edit-action-3c_Rn svg {
        margin: 0 10px 0 0;
      }
