.deprecation-header-aKl9j {
    width: calc(100% + (20px * 2));
    margin: 0 -20px;
    height: 60px;
    color: var(--steel4);
    background-color: var(--yellow3);
    line-height: 60px;
    display: flex;
    align-items: center;
  }
.deprecation-header-aKl9j .text-1dpnF {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      flex-grow: 1;
    }
.deprecation-header-aKl9j svg {
      vertical-align: middle;
      fill: var(--steel4);
      margin: 0 10px;
    }
.deprecation-header-aKl9j .actions-3o9AE {
      margin: 0 10px;
    }
.deprecation-header-aKl9j .actions-3o9AE .action-1vVRH {
        display: flex;
        align-items: center;
      }
