.container-mwF5X {
    display: flex;
    margin: 20px 0;
  }
div.profile-photo-2Id8N {
    margin-top: 5px;
    background-color: var(--steel2);
    flex-shrink: 0;
  }
.delete-option-3kMeh {
    align-items: center;
    display: flex;
    margin-left: 15px;
    margin-top: 1px;
    outline: none;
    background-color: var(--steel2);
    padding: 5px 10px;
    color: var(--aluminum2);
    border: 1px solid;
  }
.delete-option-3kMeh .delete-2rBEo {
      margin: 5px;
      margin-left: 15px;
    }
.delete-option-3kMeh .text-11Rnd {
      margin: 5px;
      flex-grow: 1;
    }
.separator-1NXNJ {
    border-left: 1px solid black;
    margin: 0 15px;
    border-color: var(--aluminum4);
  }
.created-by-2jS4x {
    display: flex;
    color: var(--steel2);
    margin: 0 0 10px;
  }
.created-by-2jS4x .name-361eg {
      font-size: 16px;
      color: var(--steel4);
    }
.title-1bqKk {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: var(--steel2);
    font-weight: 600;
    align-self: flex-end;
  }
.box-hfaSV {
    flex-grow: 1;
    margin-left: 15px;
    padding: 10px 15px;
    border: 1px solid var(--aluminum3);
    border-left: 1px solid var(--aluminum5);
    box-shadow: 2px 2px 4px var(--aluminum2);
    position: relative;

    font-family: var(--lume-g-font-family);

    font-style: normal;

    font-weight: 200;
  }
.box-hfaSV::before {
      display: table;
      content: "";
      position: absolute;
      left: -6px;
      top: 13px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid var(--aluminum5);
    }
.box-hfaSV .header-3RC3h {
      display: flex;
      align-items: center;
    }
@media (max-width: 767px) {
.box-hfaSV .header-3RC3h {
        flex-wrap: wrap
    }
      }
.box-hfaSV .header-3RC3h .rating-21Eky {
        margin-right: 10px;
        white-space: nowrap;
        font-size: 21px;
      }
.box-hfaSV .text-11Rnd {
      overflow-wrap: break-word;
      word-break: break-all;
      word-break: break-word;

      margin: 10px 0;
      white-space: pre-wrap;
    }
.review-box-3hqAN {
    flex-grow: 1;
  }
.review-actions-1l_NR {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
.review-actions-1l_NR .action-1DBAq {
      display: inline-flex;
      font-family: inherit;
      font-size: 14px;
      padding: 0;
      border: none;
      cursor: pointer;
      fill: var(--link-color);
      color: var(--link-color);
      text-decoration: none;
      background-color: transparent;
    }
.review-actions-1l_NR .action-1DBAq:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
    background-color: transparent;
    border: none;
  }
.review-actions-1l_NR .action-1DBAq {

      margin-left: 15px;
      color: var(--steel1);
      display: flex;
      align-items: center;
}
.review-actions-1l_NR .action-1DBAq span {
        display: flex;
        align-items: center;
      }
.review-actions-1l_NR .action-1DBAq svg {
        margin-right: 2px;
        flex-direction: row;
        fill: var(--steel1);
      }
.review-actions-1l_NR .action-1DBAq:hover {
        color: var(--core-blue2);
        border-color: var(--core-blue2);
      }
.review-actions-1l_NR .action-1DBAq:hover svg {
          fill: var(--core-blue2);
        }
.review-actions-1l_NR .action-1DBAq.focus-visible {
        color: var(--core-blue2);
        border-color: var(--core-blue2);
      }
.review-actions-1l_NR .action-1DBAq.focus-visible svg {
          fill: var(--core-blue2);
        }
