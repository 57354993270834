div.modal-7jzBK {
    max-width: 600px;
    min-width: 500px;
    overflow-y: auto;
  }
@media (max-width: 767px) {
div.modal-7jzBK {
    min-height: 100vh;
    min-width: 100vw;
    border: none;
  }

    div.modal-7jzBK .header-2pKgu {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    div.modal-7jzBK .body-1r2gd {
      border: none;
    }

    div.modal-7jzBK .footer-3H3Y3 {
      border: none;
    }
  }
div.modal-7jzBK .title-UVcAa {
    margin-top: 0;
    margin-bottom: 0;
  }
div.modal-7jzBK .footer-3H3Y3 {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: center;
  }
div.modal-7jzBK .footer-3H3Y3 > div {
      display: flex;
    }
div.modal-7jzBK .footer-3H3Y3 > p {
      width: 100%;
      color: var(--steel2);
    }
div.modal-7jzBK .footer-3H3Y3 button,
    div.modal-7jzBK .footer-3H3Y3 a {
      display: flex;
      align-items: center;
    }
div.modal-7jzBK .header-2pKgu > span {
      display: flex;
      align-items: center;
    }
div.modal-lg-pMCoI {
    min-width: 1024px;
  }
@media (width <= 1024px) {
div.modal-lg-pMCoI {
    min-width: 600px;
  }
  }
