.container-f5EFM {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .name-vbgld {
    font-size: 15px;
    font-weight: bold;
    min-width: 35%;
  }
  .more-options-2B9RK:hover svg {
      fill: var(--link-hover-color);
    }
  .tooltip-1kL5i > span {
    display: flex;
  }
  .deprecated-1PiqH {
    margin-left: 10px;
    padding: 4px 6px;
    border-radius: 2px;
    text-transform: uppercase;
    background: var(--yellow3);
    color: var(--tertiary);
    font-size: 10px;
    font-weight: bold;
  }
  .together-rEyJ8 {
    display: flex;
    align-items: center;
  }
  .environment-type-8X5HS {
    font-size: 14px;
    min-width: 35%;
  }
  .environment-type-8X5HS > span {
      font-weight: 600;
      margin-right: 6px;
    }
  .menu-3StSy {
    border-top: 2px solid var(--aluminum4);
  }
  .menu-3StSy > li {
      padding: 0;
    }
  .menu-3StSy > li:hover {
        color: var(--link-hover-color);
      }
  .api-manager-link-3uTYW {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    font-size: 14px;
    color: var(--steel3);
  }
  .api-manager-link-3uTYW svg {
      fill: var(--steel3);
    }
  .api-manager-link-3uTYW:hover,
    .api-manager-link-3uTYW:focus {
      color: var(--link-hover-color);
      fill: var(--link-hover-color);
    }
  .edit-icon-1dth0 {
    display: flex;
    margin-right: 6px;
  }
  .more-icon-uu2iM > svg {
    transform: rotate(90deg);
    margin: 0;
    padding: 0;
  }
