.spinner-vG3Zb {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
  }
.add-row-3sesJ {
    padding: 15px 10px;
    display: flex;
    align-items: center;
  }
.add-row-3sesJ .anypoint-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
.table-zOWoY tr td.name-2A-Ku {
        width: calc(26% - 70px);
      }
.table-zOWoY tr td.values-3zc92 {
        width: 37%;
      }
.table-zOWoY tr td.asset-types-1PKKn {
        width: 37%;
        padding: 5px 8px;
      }
.table-zOWoY tr td.actions-36Kt_ {
        width: 70px;
        white-space: nowrap;
      }
