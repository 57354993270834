.page-edit-3wY6G {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

    .page-edit-3wY6G .page-loading-2P-Gh {
      display: flex;
      margin: 20px 0;
    }

    .page-edit-3wY6G .page-name-bar-Ta8Yf {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }

    .page-edit-3wY6G .editor-page-2L9jF {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    .page-edit-3wY6G .editor-page-2L9jF textarea {
        padding: 10px 0;
      }

    .page-edit-3wY6G .editor-page-2L9jF div[role="textbox"] {
        margin: 0;
        padding: 10px 0;
      }

    .page-edit-3wY6G .editor-1B1XI {
      min-height: 0;
    }

    .page-edit-3wY6G .page-edit-form-3Ob0q {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .page-edit-3wY6G .actions-2cUuG {
      display: flex;
      justify-content: flex-end;
    }

    .page-edit-3wY6G .actions-2cUuG a,
      .page-edit-3wY6G .actions-2cUuG button {
        display: flex;
        align-items: center;
        margin: 20px 0;
        margin-right: 20px;
      }

    .page-edit-3wY6G .spinner-1o2Zc {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 13em 0;
    }

    .page-edit-3wY6G .underlying-apis-note-3IjM6 {
      padding: 12px 15px;
      background-color: var(--aluminum2);
      font-size: 12px;
      margin: 20px;
    }
