.error-2zl1J input:focus,
      .error-2zl1J input:focus:hover {
        border-left-color: var(--danger);
        border-right-color: var(--danger);
      }

  .disabled-3yEz2 {
    cursor: pointer;
    margin: 0;
  }

  .disabled-3yEz2.new-page-N1zZa {
      margin: 15px 8px;
    }

  .disabled-3yEz2 svg {
      vertical-align: middle;
      fill: var(--aluminum5);
    }

  .disabled-3yEz2 .label-2H5P8 {
      margin-left: 5px;
      font-size: 14px;
      color: var(--aluminum5);
      display: inline-block;
      vertical-align: middle;
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
    }

  .disabled-3yEz2:hover svg {
        fill: var(--aluminum5);
      }

  .disabled-3yEz2:hover .label-2H5P8 {
        color: var(--aluminum5);
      }

  .action-BkUaf {
    cursor: pointer;
    margin: 0;
  }

  .action-BkUaf.new-page-N1zZa {
      margin: 15px 8px;
    }

  .action-BkUaf svg {
      vertical-align: middle;
      fill: var(--link-color);
    }

  .action-BkUaf .label-2H5P8 {
      margin-left: 5px;
      font-size: 14px;
      color: var(--link-color);
      display: inline-block;
      vertical-align: middle;
      -webkit-user-select: none;
         -moz-user-select: none;
              user-select: none;
    }

  .action-BkUaf:hover svg {
        fill: var(--link-hover-color);
      }

  .action-BkUaf:hover .label-2H5P8 {
        color: var(--link-hover-color);
      }

  .form-2zIpi {
    flex-direction: row;
    font-size: 14px;
  }

  .form-2zIpi div {
      flex-direction: column;
      width: 100%;
      padding-top: 0;
    }

  .form-2zIpi ul {
      margin: 0;
      padding: 5px 10px;
      width: 100%;
    }

  .form-2zIpi ul li {
        width: 100%;
      }
