#react-navbar-view + #react-component-view .error-page-3sBim {
    height: calc(100vh - var(--navbar-height));
  }
#react-component-view .error-page-3sBim {
    height: 100vh;
  }
.error-page-3sBim {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 400;

    background-color: var(--steel4);
  }
@media (min-width: 768px) {
      .error-page-3sBim .status-rb2sp {
        height: 20%;
      }

      .error-page-3sBim .description-3hgGO {
        height: 15%;
      }

      .error-page-3sBim .cards-2tqjt {
        height: 65%;
      }
    }
.error-page-3sBim .status-rb2sp {
      display: flex;
      align-items: flex-end;
      padding-top: 50px;
    }
.error-page-3sBim .status-rb2sp h1 {
        font-size: 3.4em;
        margin: 0 auto;
        color: var(--tertiary);
        text-align: center;
      }
.error-page-3sBim .description-3hgGO h3 {
      font-size: 2em;
      padding-top: 15px;
      margin: 0 auto;
      color: var(--aluminum2);
      text-align: center;
    }
.error-page-3sBim .cards-2tqjt {
      display: flex;
      justify-content: center;
      background-color: var(--steel4);
      padding: 0 5%;
    }
/* Mobile, Extra Small */
@media (max-width: 767px) {
.error-page-3sBim .cards-2tqjt {
        flex-direction: column;
        padding: 50px 20%
    }
      }
.error-page-3sBim .cards-2tqjt .card-container-3yENG {
        padding: 2%;
        z-index: 2;
        display: flex;
        align-items: flex-start;
      }
@media (max-width: 767px) {
.error-page-3sBim .cards-2tqjt .card-container-3yENG {
          width: 100%
      }
        }
@media (min-width: 768px) and (max-width: 1199px) {
.error-page-3sBim .cards-2tqjt .card-container-3yENG {
          width: 30%
      }
        }
@media (min-width: 1200px) {
.error-page-3sBim .cards-2tqjt .card-container-3yENG {
          width: 25%
      }
        }
.error-page-3sBim .cards-2tqjt .card-container-3yENG .card-3g-b5 {
          cursor: pointer;
          align-items: center;
          display: flex;
          justify-content: center;
          height: 250px;
          width: 100%;
          background: rgba(80, 80, 80, 0.5);
          color: var(--aluminum2);
          fill: var(--aluminum2);
          border-top: 4px solid var(--navy3);
        }
@media (height <= 768px) {
.error-page-3sBim .cards-2tqjt .card-container-3yENG .card-3g-b5 {
            height: 200px
        }
          }
.error-page-3sBim .cards-2tqjt .card-container-3yENG .card-3g-b5:hover {
            background: rgba(80, 80, 80, 0.7);
          }
.error-page-3sBim .cards-2tqjt .card-container-3yENG .card-3g-b5 .detail-SDBpa {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
.error-page-3sBim .cards-2tqjt .card-container-3yENG .card-3g-b5 .detail-SDBpa .image-4CHka {
              padding: 15px;
            }
.error-page-3sBim .cards-2tqjt .card-container-3yENG .card-3g-b5 .detail-SDBpa .text-3_wmC {
              font-size: 1.4em;
            }
