.is-edit-1MilK {
    min-height: calc(100vh - var(--navbar-height));
    display: flex;
    flex-direction: column;
  }

    .is-edit-1MilK .content-IIWFV {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  .asset-content-article-3eOcT {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  /* stylelint-disable-next-line selector-type-no-unknown */
  .content-IIWFV {
    min-height: 400px;
    margin-top: 10px;
  }
