.grid-layout-SnTa1 {
    margin: 0 -10px;
    margin-right: -20px;
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }
/* Mobile */
@media (max-width: 479px) {
.grid-layout-SnTa1 {
      margin: 0
  }
    }
.empty-result-rNcF0 {
    padding: 90px 0;
  }
.wrapper-2ABZ4 {

    text-align: center;
  }
/* Mobile */
@media (max-width: 479px) {
.wrapper-2ABZ4 {
      width: 100%;
      padding: 10px 0
  }
    }
/* Extra Small, Small, Medium, Large */
@media (min-width: 480px) {
.wrapper-2ABZ4 {
      padding: 10px
  }
    }
/* Extra Small */
@media (min-width: 480px) and (max-width: 767px) {
.wrapper-2ABZ4 {
      width: 50%
  }
    }
/* Small, Medium */
@media (min-width: 768px) and (max-width: 1199px) {
.wrapper-2ABZ4 {
      width: 33%
  }
    }
/* Large */
@media (min-width: 1200px) and (max-width: 1599px) {
.wrapper-2ABZ4 {
      width: 25%
  }
    }
/* Extra Large */
@media (min-width: 1600px) and (max-width: 2299px) {
.wrapper-2ABZ4 {
      width: 20%
  }
    }
/* Extra Extra Large */
@media (min-width: 2300px) {
.wrapper-2ABZ4 {
      width: 16.666666%
  }
    }
.wrapper-2ABZ4 .cell-3_scQ {
      width: 100%;
      text-align: left;
      display: inline-block;
      border: 1px solid var(--aluminum3);
      box-shadow: 1px 1px 2px var(--aluminum3);
    }
