.asset-list-35-SZ {
    width: 100%;
  }

    .asset-list-35-SZ .table-19DJX td {
      padding: 0;
    }

    .asset-list-35-SZ .table-19DJX td:first-child {
        width: 30%;
      }

    .asset-list-35-SZ .table-19DJX td > * {
        padding: 10px;
      }

    .asset-list-35-SZ .open-icon-3BCjq {
      visibility: hidden;
      fill: var(--core-blue3);
      margin-left: 5px;
    }

    .asset-list-35-SZ .rating-227z- {
      white-space: nowrap;
      font-size: 21px;
      justify-content: flex-start;
    }

    .asset-list-35-SZ .name-1bru8:hover a {
          color: var(--link-color);
        }

    .asset-list-35-SZ .name-1bru8:hover .open-icon-3BCjq {
          visibility: visible;
        }

    .asset-list-35-SZ .name-1bru8 a {
        color: var(--steel3);
      }

    .asset-list-35-SZ .name-1bru8 a:hover,
        .asset-list-35-SZ .name-1bru8 a:focus {
          color: var(--core-blue2);
        }

    .asset-list-35-SZ .type-sLZcs span {
        margin-right: 5px;
      }

    .asset-list-35-SZ .type-sLZcs svg {
        vertical-align: sub;
      }
