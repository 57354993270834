.name-17OXd {
    display: flex;
  }

    .name-17OXd a {
      color: var(--steel3);
    }

    .name-17OXd a:hover,
      .name-17OXd a:focus {
        color: var(--core-blue2);
      }
