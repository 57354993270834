.page-11SaW ol,
  .page-11SaW ul,
  .page-11SaW code,
  .page-11SaW strong {
    color: var(--steel2);
  }
.page-11SaW figure {
    margin: 0;
  }
.page-11SaW code {
    white-space: pre-wrap;
    line-height: 150%;
  }
.page-11SaW a {
    color: var(--link-color);
  }
.page-11SaW a:hover {
      color: var(--core-blue2);
      cursor: pointer;
    }
.page-11SaW p,
  .page-11SaW .paragraph-31ArR {
    margin: 0;
    padding: 5px 0;
    white-space: pre-wrap;
  }
.page-11SaW .paragraph-31ArR.empty-3fW3C {
    padding: 0;
  }
.page-11SaW .paragraph-31ArR.empty-3fW3C + .paragraph-31ArR {
    padding-top: 0;
  }
.page-11SaW ol,
  .page-11SaW ul {
    margin: 10px 0;
  }
.page-11SaW img {
    display: block;
    max-width: 100%;
  }
.page-11SaW pre {
    background: var(--aluminum2);
    border: 1px solid var(--aluminum4);
    padding: 10px;
    overflow: auto;
  }
.page-11SaW hr {
    border: 0;
    border-top: 1px solid var(--aluminum3);
  }
.page-11SaW kbd {
    display: inline-block;
    padding: 3px 5px;
    font-size: 11px;
    line-height: 10px;
    color: var(--steel2);
    vertical-align: middle;
    font-family: var(--lume-g-font-family-code);
    background-color: var(--aluminum1);
    border: solid 1px var(--aluminum2);
    border-bottom-color: var(--aluminum3);
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 var(--aluminum3);
  }
.page-11SaW blockquote {
    padding: 0 10px;
    border-left: 0.25em solid var(--aluminum3);
    margin: 10px;
  }
.page-11SaW table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    overflow: auto;
    color: var(--steel1);
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 20px;
  }
.page-11SaW table tr {
      background-color: var(--tertiary);
      border-top: 1px solid var(--aluminum3);
    }
.page-11SaW table tr:nth-child(2n) {
      background-color: var(--aluminum2);
    }
.page-11SaW table th {
      color: var(--steel2);
      font-weight: bold;
    }
.page-11SaW table th img {
        margin: 0 auto;
      }
.page-11SaW table th,
    .page-11SaW table td {
      padding: 6px 13px;
      border: 1px solid var(--aluminum4);
    }
.page-11SaW table th img, .page-11SaW table td img {
        margin: 0 auto;
      }
.page-11SaW a:hover {
      text-decoration: underline;
    }
.page-11SaW {

    display: inline-block;
    overflow: auto;
    width: 100%;
}
.page-11SaW > *:first-child {
      margin-top: 0;
    }
.asset-page-3w8Rl div.play-notebook-holder > button.play-notebook {
      padding: 5px;
      margin-bottom: 20px;
    }
.header-2DVT7 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
.header-2DVT7 h2 {
      flex-grow: 1;
    }
.content-rCsmc {
    margin-top: 20px;
    min-height: 300px;
  }
.spinner-1Jo0i {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 13em 0;
  }
.markdown-3Kths {
    overflow: auto;
  }
h2.page-name-28khy {
    margin-bottom: 0;
  }
