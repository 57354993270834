.rating-15bke {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: rtl;
  }
.js-focus-visible .rating-15bke.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.rating-15bke > .star-12vqX:focus {
      text-shadow: 0 0 1px var(--steel5);
    }
.rating-15bke .star-12vqX {
      display: inline-block;
      padding: 0 0.12em;
      vertical-align: middle;
      line-height: 1;
      color: var(--aluminum4);

      font-size: 1.4em;
      width: 1em;
      height: 1em;
      cursor: pointer;
      fill: var(--aluminum4);
    }
.rating-15bke .star-12vqX:hover,
    .rating-15bke .selected-nHq1A {
      color: var(--yellow3);
    }
.rating-15bke .star-12vqX:hover ~ svg, .rating-15bke .selected-nHq1A ~ svg {
        fill: var(--yellow3);
      }
.rating-15bke svg {
      width: 100%;
      height: 100%;
    }
