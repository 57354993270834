.search-3kHya {
    margin-right: 10px;
    flex-grow: 1;
  }

    .search-3kHya .text-field-3eMP8 {
      width: 100%;
    }

    .search-3kHya input::-webkit-search-decoration,
    .search-3kHya input::-webkit-search-cancel-button,
    .search-3kHya input::-webkit-search-results-button,
    .search-3kHya input::-webkit-search-results-decoration {
      display: none;
    }
