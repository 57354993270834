button.button-2vlQw {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
.back-to-previous-5nm_d {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
.container-1p6EL {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }
.actions-eKmDa {
    display: flex;
    align-items: center;
    flex-direction: row;
    place-content: space-between flex-end;
    margin-left: 30px;
  }
.actions-eKmDa .action-3aCKx > span {
        display: flex;
      }
.actions-eKmDa > * {
      margin-left: 10px;
    }
.actions-eKmDa > *:first-child {
        margin-left: 0;
      }
.header-10xVF {
    display: flex;
    flex-grow: 1;
    clear: both;
    align-items: center;
  }
@media (max-width: 767px) {
.header-10xVF {
      flex-direction: column
  }
    }
.header-10xVF .api-version-I8Vi4 {
      display: flex;
      align-items: center;
    }
.header-10xVF .visibility-3nD0I {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 9px;
      color: var(--aluminum5);
      border-left: 1px solid var(--aluminum5);
      font-size: 13px;
    }
.header-10xVF .visibility-3nD0I.placeholder-15r8f {
        border: none;
      }
.header-10xVF .dropdown-value-2ua5M {
      padding: 3px;
      padding-left: 15px;
    }
.header-10xVF .version-group-dropdown-value-5lSQy {
      display: flex;
      font-size: 17px;
      padding-left: 10px;
      cursor: default;
    }
.header-10xVF .title-3ezdM {
      justify-content: space-between;
      margin-left: 10px;
      width: 100%;
      flex-grow: 1;
    }
@media (max-width: 767px) {
.header-10xVF .title-3ezdM {
        margin-left: 0
    }
      }
.header-10xVF .title-3ezdM .separator-38L2Z {
        border-left: 1px solid black;
        margin: 0 15px;
        border-color: var(--aluminum5);
      }
.header-10xVF .title-3ezdM .main-heading-2Ceh1 {
        display: flex;
        justify-content: space-between;
      }
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
@media (max-width: 767px) {
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx {
            flex-direction: column
        }
          }
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx .main-separator-1xArJ {
            height: 25px;
            margin: auto 10px;
          }
@media (max-width: 767px) {
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx .main-separator-1xArJ {
              display: none
          }
            }
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx .dropdown-container-3Ywqh {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            z-index: 2;
          }
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx .dropdown-container-3Ywqh .version-group-label-mz6YJ {
              max-width: 200px;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 18px;
              margin: 0 5px;
            }
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx .dropdown-container-3Ywqh .minor-version-1Kfrb {
              max-width: 100px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 20px;
              padding: 0 10px;
            }
.header-10xVF .title-3ezdM .main-heading-2Ceh1 .main-sub-heading-3X9cx .dropdown-container-3Ywqh li .selected-rN4_P > a {
              color: var(--aluminum1);
            }
.header-10xVF .title-3ezdM .sub-heading-2x0dc .rating-header-13Uef {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
.header-10xVF .title-3ezdM .sub-heading-2x0dc > div:first-child {
          margin-right: 5px;
        }
.header-10xVF .title-3ezdM .sub-heading-2x0dc > span {
          vertical-align: middle;
        }
.header-10xVF .title-3ezdM .sub-heading-2x0dc .rating-label-f7-N3 {
          display: flex;
          align-items: center;
          margin-right: 20px;
          flex-wrap: wrap;
        }
.header-10xVF .title-3ezdM .sub-heading-2x0dc .rating-label-f7-N3 .rating-2F6dG {
            display: inline-flex;
            font-size: 23px;
          }
.header-10xVF .title-3ezdM .sub-heading-2x0dc .rating-label-f7-N3 .rating-detail-EHEea {
            color: var(--steel2);
          }
.header-10xVF .title-3ezdM .sub-heading-2x0dc .review-button-3svFy {
          display: inline-flex;
          font-family: inherit;
          font-size: 14px;
          padding: 0;
          border: none;
          cursor: pointer;
          fill: var(--link-color);
          color: var(--link-color);
          text-decoration: none;
          background-color: transparent;
        }
.header-10xVF .title-3ezdM .sub-heading-2x0dc .review-button-3svFy:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
    background-color: transparent;
    border: none;
  }
