 {
  /* stylelint-enable selector-class-pattern */
}
/* stylelint-disable selector-class-pattern */
div.DropdownMenu-Tu2EO {
    overflow-wrap: break-word;
    word-break: break-all;
    word-break: break-word;

    white-space: nowrap;
    margin: auto 0;
    padding: 0;
  }
.label-2bHqO {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 400;

    color: var(--text-color);
    font-size: 18px;
    padding: 15px 15px 0;
  }
.label-2bHqO:first-of-type {
      padding-top: 5px;
    }
.list-2JS6c {
    max-height: 250px;
  }
div.DropdownMenuMenu-3XN_F {
    min-width: auto;
  }
.DropdownMenuContent-3_P5v {
    left: initial;
    right: 1px;
  }
.DropdownMenuContent-3_P5v span {
      max-width: 140px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
.DropdownMenuToggle-3yJls {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 200;
  }
.DropdownMenuToggle-3yJls > span,
    .DropdownMenuToggle-3yJls > .DropdownMenuIcon-301Gq {
      color: var(--steel2);
      font-size: 20px;
    }
.DropdownMenuToggle-3yJls > .DropdownMenuIcon-301Gq {
      height: 20px;
      width: 20px;
    }
.DropdownMenuToggle-3yJls a {
      color: var(--steel2);
    }
.DropdownMenuToggle-3yJls:hover > span,
      .DropdownMenuToggle-3yJls:hover > .DropdownMenuIcon-301Gq {
        color: var(--core-blue2);
        fill: var(--core-blue2);
      }
.DropdownMenuToggle-3yJls:hover > span span, .DropdownMenuToggle-3yJls:hover > .DropdownMenuIcon-301Gq span {
          color: var(--core-blue2);
        }
.DropdownMenuToggle-3yJls:hover > .DropdownMenuIcon-301Gq {
        height: 20px;
        width: 20px;
      }
.DropdownMenuToggle-3yJls:hover > span > div > a {
        color: var(--core-blue2);
        fill: var(--core-blue2);
      }
.headItem-31wKw {
    color: var(--steel2);
    padding: 0;
  }
.headItem-31wKw svg {
      color: var(--steel2);
      fill: var(--steel2);
    }
ul.list-2JS6c > li {
    padding: 0;
    padding-right: 10px;
  }
ul.list-2JS6c > li a {
      color: var(--steel2);
      display: block;
      padding: 5px 10px;
    }
ul.list-2JS6c > li a {
      padding-left: 0;
    }
