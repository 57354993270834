.more-actions-3C8cI {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    height: 40px;
    width: 40px;
  }
.more-actions-3C8cI .more-actions-icon-2r_Jq {
      display: flex;
      align-items: center;
      justify-content: center;
      fill: var(--steel1);
      padding: 0;
      margin: 0;
      border: none;
      transform: rotate(90deg);
    }
.more-actions-3C8cI .more-actions-icon-2r_Jq:hover {
        cursor: pointer;
        fill: var(--robust-blue1);
      }
.actions-container-1Udlm {
    display: flex;
  }
.more-actions-menu-3Y5OX {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid var(--aluminum3);
    background-color: var(--tertiary);
    box-shadow: 2px 2px 4px var(--aluminum3);
  }
.more-actions-item-button-1ZPNb {
    display: flex;
    align-items: center;
    padding: 5px 0;
    border: 0;
    font-size: 14px;
    cursor: pointer;
    background: none;
  }
.delete-application-17VS8:hover {
    fill: var(--danger);
  }
.delete-application-17VS8:hover button {
      color: var(--danger);
    }
.reset-client-secret-29QR9:hover {
    fill: var(--primary);
  }
.reset-client-secret-29QR9:hover button {
      color: var(--primary);
    }
.icon-3W0Rg {
    display: flex;
    align-items: center;
    margin-right: 7px;
  }
.more-actions-separator-1WRAq {
    width: 100%;
    margin: 0;
    color: var(--aluminum4);
  }
