.sidebar-3d2-d {
    background-color: var(--aluminum1);
  }

    @media (max-width: 991px) {.sidebar-3d2-d {
      border-bottom: 1px solid var(--aluminum3);
      /* stylelint-disable declaration-no-important */
      width: auto !important; /* sorry not sorry */
      max-width: 100% !important
      /* stylelint-enable declaration-no-important */
  }
    }

    @media (min-width: 992px) {.sidebar-3d2-d {
      border-right: 1px solid var(--aluminum3)
  }
    }

    .sidebar-3d2-d hr {
      margin: 2px 10px;
    }
