.asset-card-2hkBS {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 400;

    display: block;
    height: 180px;
    padding: 10px 15px 15px;
    /* stylelint-disable-next-line declaration-no-important */
    background-color: var(--tertiary) !important; /* needed to override anypoint styles */
    transition: 0.3s ease-out;
  }
/* Small, Medium, Large */
@media (min-width: 480px) {
.asset-card-2hkBS {
      width: 100%
  }
    }
.asset-card-2hkBS span:first-child {
      font-size: 0.75em;
    }
.asset-card-2hkBS span:first-child .anypoint-icon {
        font-size: 16px;
      }
.asset-card-2hkBS.public-portal--Y8_t {
      height: 160px;
    }
.asset-card-2hkBS:hover {
      box-shadow: 0 2px 4px 2px var(--aluminum3);
      transform: scale(1.05);
      transition: 0.1s ease-out;
    }
.asset-card-2hkBS:focus {
      box-shadow: 0 2px 4px 2px var(--aluminum5);
    }
.asset-card-2hkBS .header-1EzDs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 18px;
    }
.asset-card-2hkBS .header-1EzDs .rating-21mXC {
        padding: 1px;
        height: 18px;
        line-height: 18px;
        vertical-align: top;
      }
.asset-card-2hkBS .header-1EzDs .rating-21mXC span {
          font-size: 12px;
        }
.asset-card-2hkBS .content-yavX4 {
      font-size: 1em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100% - 18px);
    }
.asset-card-2hkBS .content-yavX4 .asset-image-2rwmi {
        margin-top: 10px;
      }
.asset-card-2hkBS .content-yavX4 .name-2F7QN {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        width: 100%;
        text-align: center;
        margin-top: 10px;
        height: 38px;
        white-space: inherit;
        flex-grow: 1;
        color: var(--steel4);
        font-size: 14px;
        line-height: 18px;
      }
.asset-card-2hkBS .content-yavX4.public-portal--Y8_t .name-2F7QN {
        flex-grow: 0;
      }
.asset-card-2hkBS .content-yavX4 .created-by-q61Sm {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 100%;
        color: var(--text-color);
        font-size: 12px;
        line-height: 18px;
      }
