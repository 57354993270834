.container-2KLLb {
    border: none;
  }
@media (max-width: 991px) {
.container-2KLLb {
      background-color: var(--steel4);
      z-index: 1;
      display: none
  }

      .container-2KLLb.is-open-mobile-menu-3dGP8 {
        display: block;
        top: var(--navbar-height);
        left: 0;
        width: 100%;
        position: absolute;
      }
    }
.container-2KLLb .tab-2dgfp {
      opacity: 1;
      font-size: 15px;
      white-space: nowrap;
      width: auto;
      padding: 0;
    }
.container-2KLLb .tab-2dgfp a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 250px;
        color: var(--aluminum3);
        padding: 17px 20px 20px;
        display: block;
        position: relative;
        border-bottom: 3px solid transparent;
      }
.container-2KLLb .tab-2dgfp a.active-page-3cu9n {
          border-color: var(--core-blue2);
        }
.container-2KLLb .tab-2dgfp a:hover,
        .container-2KLLb .tab-2dgfp a:focus {
          border-color: var(--core-blue2);
        }
@media (max-width: 991px) {
.container-2KLLb .tab-2dgfp {
        width: 100%;
        text-align: left
    }

        .container-2KLLb .tab-2dgfp::after {
          display: none;
        }

        .container-2KLLb .tab-2dgfp span {
          display: block;
        }
      }
@media (max-width: 991px) {
.container-2KLLb .tab-2dgfp.selected-2TCC0 {
          background-color: var(--steel5)
      }

          .container-2KLLb .tab-2dgfp.selected-2TCC0::after {
            display: none;
          }
        }
.container-2KLLb .tab-2dgfp.selected-2TCC0 a {
          color: var(--core-blue2);
        }
.container-2KLLb .tab-2dgfp.selected-2TCC0::after {
          background-color: var(--core-blue2);
        }
@media (max-width: 991px) {
.container-2KLLb .list-TZs4u {
        background-color: var(--steel4)
    }
      }
.container-2KLLb .list-TZs4u > div {
        opacity: 0;
      }
.container-2KLLb .list-TZs4u ul {
        margin: 0;
        margin-bottom: 0;
      }
.container-2KLLb .list-TZs4u ul li {
          bottom: 0;
        }
.container-2KLLb .list-TZs4u ul li::after {
            background-color: rgba(39, 47, 51, 0.1);
            bottom: 0;
          }
