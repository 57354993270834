.container-1P4uy {
    display: flex;
    min-height: 100%;
    flex-grow: 1;
    flex-direction: column;
  }
.container-1P4uy.edit-mode-QirlQ {
      pointer-events: none;
    }
/* Mobile, Extra Small */
@media (max-width: 991px) {
      .container-1P4uy.edit-mode-QirlQ {
        display: none;
      }
    }
.container-1P4uy .search-bar-1b5CS > form {
        margin-right: 0;
      }
.table-2-DjK {
    margin-top: 20px;
  }
.table-2-DjK tr td:first-child {
    width: 40%;
  }
.table-2-DjK td {
    padding: 0;
  }
.table-2-DjK td > * {
      padding: 15px 10px;
    }
.spinner-q7scx {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
  }
