@media (min-width: 992px) {
.home-welcome-wrapper-zXEJ7 {
      min-height: calc(100% - 60px);
      display: flex;
      flex-direction: column
  }
    }
.home-3C7pT {
    padding: 0 20px 20px;
    margin: 0 -20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
.home-3C7pT.marketing-card-view--hRq3 {
      min-height: 1000px;
    }
.home-3C7pT.edit-mode-33tbs {
      pointer-events: none;
      opacity: 0.5;
    }
.home-3C7pT.is-welcome-enabled-1uw2i {
      min-height: calc(100% - 220px);
    }
/* Mobile, Extra Small */
@media (max-width: 991px) {
      .home-3C7pT.edit-mode-33tbs {
        display: none;
      }
    }
.home-3C7pT .title-container-2KpAt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -20px;
      padding: 20px 20px 10px;
    }
.home-3C7pT .title-d6Caa {
      margin: 0;
      display: inline-block;
    }
.home-3C7pT .title-d6Caa .annotation-2hMgw {
        color: var(--text-color);
        margin-left: 5px;
        font-size: 12px;
        font-family: var(--lume-g-font-family);
        font-style: normal;
        font-weight: 400;

        position: relative;
        bottom: 2px;
        font-size: 14px;
      }
.home-3C7pT .domain-header-3j2mZ {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
.home-3C7pT .domain-title-3Yyqw {
      margin: var(--padding-md) 0;
      color: var(--steel5);
      font-size: 24px;
    }
.home-3C7pT .refine-1GTBc {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
      color: var(--steel1);
    }
.home-3C7pT .go-to-top-3ej1X {
      display: inline-flex;
      font-family: inherit;
      font-size: 14px;
      padding: 0;
      border: none;
      cursor: pointer;
      fill: var(--link-color);
      color: var(--link-color);
      text-decoration: none;
      background-color: transparent;
    }
.home-3C7pT .go-to-top-3ej1X:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
    background-color: transparent;
    border: none;
  }
.home-3C7pT .go-to-top-3ej1X {

      display: flex;
      padding: 10px;
      align-items: center;
      justify-content: center
}
.home-3C7pT .go-to-top-3ej1X .anypoint-icon {
        display: flex;
        margin-right: 5px;
        transform: rotate(90deg);
      }
.white-theme-16Xlt {
    background-color: var(--tertiary);
  }
.grey-theme-3ZBq- {
    background-color: var(--aluminum2);
  }
