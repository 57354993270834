.header-1laA1 {
    background-color: var(--aluminum1);
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--aluminum3);
    cursor: pointer;
    margin-top: 10px;
    padding: 15px;
  }

    .header-1laA1 .title-cz2YM {
      font-size: 16px;
      flex-grow: 1;
      text-align: left;
    }

    .header-1laA1 .icon-Pt0_r {
      display: flex;
      color: var(--steel1);
      fill: var(--steel1);
      justify-content: middle;
      align-items: center;
      padding: 0;
      margin-right: 10px;
    }

    .header-1laA1 .closed-DNRKQ {
      transform: rotate(-90deg);
    }
  .content-93Rls {
    padding: 20px;
    border: 1px solid var(--aluminum3);
    border-top: none;
  }
