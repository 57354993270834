.actions-footer-2tMMT {
    font-size: 12px;
    color: var(--steel2);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.actions-n2GAo {
    display: flex;
  }
.actions-n2GAo .anypoint-icon {
      display: flex;
    }
.edit-icon-2U6d_ {
    padding: 5px;
    cursor: pointer;
  }
.asset-description-355HR h5 {
      white-space: pre-line;
      cursor: pointer;
    }
.asset-description-355HR:focus .edit-icon-2U6d_ {
        fill: var(--primary);
      }
.asset-no-description-Bv-z1 {
    color: var(--steel2);
    font-style: italic;
  }
.asset-no-description-Bv-z1:focus .edit-icon-2U6d_ {
        fill: var(--primary);
      }
.description-qos09 {
    font-family: var(--lume-g-font-family);
    font-style: italic;
    font-weight: 200;

    margin: 10px 0;
    font-size: 19px;
    display: block;
  }
.description-qos09.disabled-3ZSF0 {
      color: var(--aluminum4);
    }
div.edit-description-hMwx0 {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 400;

    font-size: 17px;
  }
div.edit-description-hMwx0 textarea {
      overflow: auto;
      font-size: 17px;
      padding-bottom: 32px;
    }
.action-1ZoFO {
    margin-left: 10px;
    cursor: pointer;
  }
.action-1ZoFO:hover,
    .action-1ZoFO:focus {
      fill: var(--primary);
    }
