.page-name-2NGx1 {
    margin: 10px 0;
    margin-right: var(--padding-lg);
    flex-grow: 1;
  }

    .page-name-2NGx1 .page-name-input-1vsFh input {
        font-family: var(--lume-g-font-family);
        font-style: normal;
        font-weight: 400;

        width: 100%;
        padding: 4px 10px;
        font-size: 20px;
      }
