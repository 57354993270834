.table-6REji {
    width: 100%;
    background-color: var(--tertiary);
    border-collapse: collapse;
  }

.table-6REji th {
      padding: 10px;
      color: var(--steel2);
      border-bottom: 3px solid var(--aluminum3);
      text-align: left;
    }

.table-6REji tbody tr:hover {
        background-color: var(--aluminum1);
      }

.table-6REji td {
      overflow-wrap: break-word;
      word-break: break-all;
      word-break: break-word;
      padding: 10px;
      color: var(--steel1);
      display: table-cell;
      vertical-align: middle;
      border-bottom: 1px solid var(--aluminum3);
    }

.table-6REji tr th,
    .table-6REji tr td {
      color: var(--steel4);
      border-bottom: 1px solid var(--aluminum3);
    }

.table-6REji tr th {
      width: 22%;
    }

.table-6REji tr th:nth-child(4) {
      width: 34%;
    }

.table-6REji tr td {
      vertical-align: top;
    }

.table-6REji tr:last-of-type td {
      border-bottom: none;
    }
