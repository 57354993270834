 {
  /* stylelint-enable selector-class-pattern */
}
/* stylelint-disable selector-class-pattern */
.DropdownMenu-2Zz0r {
    overflow-wrap: break-word;
    word-break: break-all;
    word-break: break-word;

    pointer-events: none;
    margin: auto 0;
    padding: 0;
  }
.DropdownMenuIcon-1e5Mu {
    display: none;
  }
.DropdownMenuToggle-2pP1V {
    font-family: var(--lume-g-font-family);
    font-style: normal;
    font-weight: 200;
  }
.DropdownMenuToggle-2pP1V > span {
      color: var(--steel2);
      font-size: 20px;
    }
.DropdownMenuToggle-2pP1V a {
      color: var(--steel2);
    }
