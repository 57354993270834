.pill-37RRc {
    margin-right: 4px;
    margin-bottom: 4px;
    max-width: 100%;
  }
.pill-37RRc .pill-span-29Ebp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
.js-focus-visible .pill-37RRc.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.pill-37RRc {

    display: inline-block;
}
.pill-tag-1wXm3 {
    max-width: inherit;
    white-space: normal;
  }
