.container-22dS_ {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0 -20px;
  }
.icon-1OdfE {
    vertical-align: middle;
  }
.navigation-link-2-n-q {
    color: var(--steel3);
    fill: var(--steel3);
  }
.navigation-link-2-n-q .label-18COm {
      padding-left: 7px;
    }
.header-114xS {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
  }
.spinner-2fpVy {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
  }
