div.modal-3-x2Y {
    max-width: 600px;
    min-width: 500px;
    overflow-y: auto;
  }
@media (max-width: 767px) {
div.modal-3-x2Y {
    min-height: 100vh;
    min-width: 100vw;
    border: none
  }

    div.modal-3-x2Y .header-2lusG {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    div.modal-3-x2Y .body-2q_LC {
      border: none;
    }

    div.modal-3-x2Y .footer-25XWx {
      border: none;
    }
  }
div.modal-3-x2Y .title-1YlVn {
    margin-top: 0;
    margin-bottom: 0;
  }
div.modal-3-x2Y .footer-25XWx {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: center;
  }
div.modal-3-x2Y .footer-25XWx > div {
      display: flex;
    }
div.modal-3-x2Y .footer-25XWx > p {
      width: 100%;
      color: var(--steel2);
    }
div.modal-3-x2Y .footer-25XWx button,
    div.modal-3-x2Y .footer-25XWx a {
      display: flex;
      align-items: center;
    }
div.modal-3-x2Y .header-2lusG > span {
      display: flex;
      align-items: center;
    }
div.modal-3-x2Y .icon-jxVMY {
      margin-right: 10px;
      vertical-align: middle;
      color: var(--tertiary);
      fill: var(--tertiary);
    }
div.modal-3-x2Y .icon-jxVMY svg {
        color: var(--tertiary);
        fill: var(--tertiary);
      }
div.modal-lg-3RhEs {
    min-width: 1024px;
  }
@media (width <= 1024px) {
div.modal-lg-3RhEs {
    min-width: 600px;
  }
  }
