.grey-theme-1qbag {
    background-color: var(--aluminum1);
  }
  .wrapper-DtTI8 {
    margin: 0 calc(var(--padding-lg) * -1);
    padding: var(--padding-md) var(--padding-lg) 0;
  }
  .callout-1o-GQ > .anypoint-icon {
      margin-top: 7.5px;
      margin-right: 10px;
    }
  .children-3HE7F {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
  .children-3HE7F > button {
      margin-left: auto;
    }
  .children-3HE7F > button + .icon-2kIY2 {
        margin-left: 0;
      }
  .icon-2kIY2 {
    fill: var(--core-blue4);
    display: flex;
    margin-left: auto;
    margin-top: 12px;
    padding-left: 10px;
    cursor: pointer;
    align-self: flex-start;
  }
