.empty-section-1oKYf {
    height: 440px;
  }
  .header-2Vc_9 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .empty-description-3Qqnz {
    text-align: center;
    width: 360px;
  }
  .callout-3FSWF {
    margin-top: 10px;
  }
