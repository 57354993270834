.toggle-container-3F-hc {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .toggle-1k6m8 {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  .tooltip-2fNjR {
    min-width: 270px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    color: var(--steel1);
  }
  .link-anchor-2o4Ku {
    display: inline-flex;
  }
