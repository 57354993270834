.actions-section-2Rpvq {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--padding-sm);
    color: var(--text-color);
  }

    .actions-section-2Rpvq .search-result-info-2aZHL {
      color: var(--steel5);
      display: flex;
      align-items: center;
    }

    .actions-section-2Rpvq .save-search-button-3BAh8 {
      display: flex;
      margin-left: var(--padding-sm);
      align-items: center;
    }
