.tooltip-container-16MjT {
    display: flex;
    align-items: center;
  }
  .tooltip-21vGd {
    font-size: 12px;
    width: 220px;
    color: var(--steel1);
  }
  .tooltip-21vGd .link-anchor-38iFo {
      display: flex;
      margin-top: 10px;
    }
  .delete-page-2H3Qp {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--steel2);
    fill: var(--steel2);
  }
  .delete-page-2H3Qp:hover,
    .delete-page-2H3Qp:focus {
      color: var(--core-blue2);
      fill: var(--core-blue2);
    }
  .delete-page-2H3Qp .icon-oMN7n {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  .delete-page-2H3Qp.disabled-1LWE8 * {
      color: var(--aluminum4);
      fill: var(--aluminum4);
    }
  .delete-page-2H3Qp.disabled-1LWE8 *:hover,
      .delete-page-2H3Qp.disabled-1LWE8 *:focus {
        color: var(--aluminum4);
        fill: var(--aluminum4);
      }
