.category-Yb_Ve {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--aluminum3);
  }
.category-Yb_Ve:first-child {
      padding-top: 0;
    }
.category-Yb_Ve:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
.category-Yb_Ve .name-qa8Kx {
      color: var(--steel2);
      margin-bottom: 5px;
      font-size: 1.1em;
    }
.category-Yb_Ve .name-qa8Kx .trash-icon-1KiP9 {
        fill: var(--steel2);
        vertical-align: middle;
        margin-left: 5px;
      }
.category-Yb_Ve .name-qa8Kx .trash-icon-1KiP9:hover,
        .category-Yb_Ve .name-qa8Kx .trash-icon-1KiP9.focus-visible {
          cursor: pointer;
          fill: var(--core-blue3);
        }
.category-Yb_Ve .values-HPjSx {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
    }
.category-Yb_Ve .values-HPjSx .pill-2z3AX {
        margin-right: 4px;
        margin-bottom: 4px;
        max-width: 100%;
      }
.category-Yb_Ve .values-HPjSx .pill-2z3AX .pill-span-b043r {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
.js-focus-visible .category-Yb_Ve .values-HPjSx .pill-2z3AX.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.category-Yb_Ve .values-HPjSx .pill-2z3AX {

        display: inline-block;
}
.category-Yb_Ve .values-HPjSx .value-select-3mE8a {
        min-width: 100%;
      }
