h6.header-1wfs0 {
    margin: 10px 20px;
    color: var(--robust-blue2);
    font-size: 13px;
  }
.item-17zqS {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    width: 100%;
    border-left: 4px solid transparent;
    padding: 10px 15px;
    color: var(--text-color);
    align-items: center;
    margin: 5px 0;
  }
.item-17zqS:first-child {
      margin-top: 0;
    }
.item-17zqS.hierarchy-2-1fcJ5 {
      padding: 6px 10px 6px 20px;
      color: var(--text-color);
      width: calc(100% - 4px);
      margin-left: 4px;
    }
.item-17zqS.hierarchy-2-1fcJ5.active-21Dlm,
      .item-17zqS.hierarchy-2-1fcJ5.active-21Dlm:hover {
        background-color: var(--aluminum2);
        border-left-color: var(--robust-blue2);
      }
.item-17zqS.hierarchy-2-1fcJ5:hover {
        border-left-color: var(--robust-blue1);
      }
.item-17zqS.hierarchy-2-1fcJ5 .content-1xEcR .label-1aUi6 {
          font-size: 13px;
        }
.item-17zqS.hierarchy-2-1fcJ5 .content-1xEcR .annotation-3t7aD {
          color: var(--text-color);
          margin-left: 5px;
          font-size: 12px;
        }
.item-17zqS .open-icon-kFWpe {
      padding-left: 5px;
    }
.item-17zqS .open-icon-kFWpe svg {
        fill: var(--steel2);
      }
.item-17zqS .content-1xEcR {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
.item-17zqS .content-1xEcR .icon-1_ukp {
        display: flex;
        padding-right: 5px;
      }
.item-17zqS .content-1xEcR .icon-1_ukp svg {
          fill: var(--steel2);
        }
.item-17zqS .content-1xEcR .icon-1_ukp.error-qWuEH svg {
          fill: var(--danger);
        }
.item-17zqS .content-1xEcR .label-1aUi6 {
        align-self: center;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
      }
.item-17zqS .content-1xEcR .label-1aUi6 + .icon-1_ukp {
        padding-left: 5px;
      }
.item-17zqS .suffix-B0CBD {
      display: flex;
      align-items: center;
    }
.item-17zqS.disabled-9A1jK {
      cursor: default;
    }
.item-17zqS:hover:not(.disabled-9A1jK),
    .item-17zqS:focus {
      border-left-color: var(--core-blue1);
    }
.item-17zqS:hover:not(.disabled-9A1jK) .open-icon-kFWpe svg, .item-17zqS:focus .open-icon-kFWpe svg {
          fill: var(--steel5);
        }
.item-17zqS:hover:not(.disabled-9A1jK) .content-1xEcR, .item-17zqS:focus .content-1xEcR {
        color: var(--steel5);
      }
.item-17zqS:hover:not(.disabled-9A1jK) .content-1xEcR .icon-1_ukp svg, .item-17zqS:focus .content-1xEcR .icon-1_ukp svg {
            fill: var(--steel5);
          }
.item-17zqS:focus,
    .item-17zqS.active-21Dlm {
      border-left-color: var(--core-blue3);
    }
.item-17zqS.active-21Dlm {
      background-color: var(--aluminum3);
    }
.item-17zqS.active-21Dlm .content-1xEcR {
        color: var(--steel5);
      }
.item-17zqS.active-21Dlm .content-1xEcR .icon-1_ukp svg {
            fill: var(--steel5);
          }
