 {
}
.container-2r3_T {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 220px;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 -20px;
  }
.container-2r3_T header.content-3Nh0N {
      width: 100%;
      max-width: 906px;
    }
.container-2r3_T header.content-3Nh0N input,
      .container-2r3_T header.content-3Nh0N textarea {
        color: var(--tertiary);
        background-color: rgba(255, 255, 255, 0.25);
        text-align: inherit;
        padding-top: 10px;
        padding-bottom: 10px;
      }
.container-2r3_T header.content-3Nh0N input:focus,
        .container-2r3_T header.content-3Nh0N input:hover,
        .container-2r3_T header.content-3Nh0N textarea:focus,
        .container-2r3_T header.content-3Nh0N textarea:hover {
          /* stylelint-disable-next-line declaration-no-important */
          background-color: rgba(255, 255, 255, 0.25) !important; /* anypoit-components fix */
        }
.container-2r3_T h1.title-HUG0s {
      font-size: 35px;
      font-weight: bold;
      margin: 0 auto 30px;
    }
.container-2r3_T h1.title-HUG0s input {
        font-family: var(--lume-g-font-family);
        font-style: normal;
        font-weight: 400;

        font-size: inherit;
      }
.container-2r3_T h2.text-22CG4 {
      font-family: var(--lume-g-font-family);
      font-style: normal;
      font-weight: 400;

      font-size: 20px;
    }
.container-2r3_T h2.text-22CG4 textarea {
        overflow: auto;
        font-size: inherit;
      }
