div .action-bJvVN {
    display: inline-flex;
    align-items: center;
  }
div .action-bJvVN .label-_9Rn1 {
      margin-left: 5px;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
    }
div .action-bJvVN > span {
      display: flex;
    }
div .action-bJvVN svg {
      vertical-align: middle;
      fill: var(--steel1);
    }
.menu-34Mte {
    border-top: 2px solid var(--aluminum4);
  }
.download-3cDfz:focus {
      color: var(--core-blue2);
      border-color: var(--core-blue2);
    }
.download-3cDfz:focus svg {
        fill: var(--core-blue2);
      }
.disabled-item-tAZeU {
    color: var(--aluminum5);
  }
.tooltip-2suat {
    padding: 5px 10px;
    border: 1px solid var(--steel1);
    background: var(--tertiary);
    color: var(--steel1);
  }
.popover-anchor-1DD-0 {
    color: var(--robust-blue3);
    display: block;
  }
.popover-anchor-1DD-0:hover {
      color: var(--core-blue2);
    }
