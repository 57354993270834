.created-by-3d1di {
    display: flex;
    align-items: center;
    color: var(--text-color);
    white-space: nowrap;
  }
.created-by-3d1di .icon-352bQ {
    margin-right: 10px;
    display: flex;
  }
.created-by-3d1di .icon-352bQ svg {
      fill: var(--steel3);
    }
.created-by-3d1di {

    overflow: hidden;
    text-overflow: ellipsis;
}
.created-by-3d1di .profile-photo-3KVJ8 {
      background-color: var(--steel2);
      vertical-align: middle;
      margin-right: 10px;
      display: inline-flex;
      width: 25px;
      height: 25px;
      font-size: 10px;
    }
