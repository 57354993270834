.row-xa_jI:hover .api-link-NEheM a {
        color: var(--link-hover-color);
      }
      .row-xa_jI:hover .open-icon-2G5Dr {
        visibility: visible;
      }
  .api-link-NEheM a {
    color: unset;
  }
  .open-icon-2G5Dr {
    visibility: hidden;
  }
  .open-icon-2G5Dr svg {
      fill: var(--link-hover-color);
    }
  .api-restricted-nJ_HN {
    cursor: not-allowed;
  }
  .minor-version-pill-1sCFB {
    font-size: 12px;
    padding: 4px 6px;
    margin: 0 12px;
    border-radius: 2px;
    background-color: var(--aluminum3);
  }
