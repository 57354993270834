.page-edit-3Ej8L {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
.page-edit-3Ej8L .page-name-bar-3oMBP {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
.page-edit-3Ej8L .editor-page-C8w4c {
      display: flex;
      flex-grow: 1;
      margin: 0 -20px;
    }
.page-edit-3Ej8L .editor-1Y_Rz {
      min-height: 0;
    }
.page-edit-3Ej8L .page-edit-form-1745W {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
.page-edit-3Ej8L .actions-zjdt4 {
      display: flex;
      justify-content: flex-end;
    }
.page-edit-3Ej8L .actions-zjdt4 a,
      .page-edit-3Ej8L .actions-zjdt4 button {
        display: flex;
        align-items: center;
        margin: 20px 0;
        margin-right: 20px;
      }
.page-edit-3Ej8L .spinner-gPgrU {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 13em 0;
    }
.page-edit-3Ej8L .underlying-apis-note-3ngqD {
      padding: 12px 15px;
      background-color: var(--aluminum2);
      font-size: 12px;
      margin: 20px;
    }
