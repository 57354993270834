div.modal-13ZRq {
    max-width: 600px;
    min-width: 500px;
    overflow-y: auto;
  }
@media (max-width: 767px) {
div.modal-13ZRq {
    min-height: 100vh;
    min-width: 100vw;
    border: none
  }

    div.modal-13ZRq .header-BYA78 {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    div.modal-13ZRq .body-38Jus {
      border: none;
    }

    div.modal-13ZRq .footer-22DDN {
      border: none;
    }
  }
div.modal-13ZRq .title-11GoA {
    margin-top: 0;
    margin-bottom: 0;
  }
div.modal-13ZRq .footer-22DDN {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: center;
  }
div.modal-13ZRq .footer-22DDN > div {
      display: flex;
    }
div.modal-13ZRq .footer-22DDN > p {
      width: 100%;
      color: var(--steel2);
    }
div.modal-13ZRq .footer-22DDN button,
    div.modal-13ZRq .footer-22DDN a {
      display: flex;
      align-items: center;
    }
div.modal-13ZRq .header-BYA78 > span {
      display: flex;
      align-items: center;
    }
div.modal-13ZRq .icon-Qnko4 {
      margin-right: 10px;
      vertical-align: middle;
      color: var(--tertiary);
      fill: var(--tertiary);
    }
div.modal-13ZRq .icon-Qnko4 svg {
        color: var(--tertiary);
        fill: var(--tertiary);
      }
