.wrapper-fub0d {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 70px;
    pointer-events: none;
    z-index: 1041;
  }
.alert-message-lYz9C {
    max-width: calc(100% - 40px);
    color: var(--tertiary);
    box-shadow: 1px 1px 2px var(--aluminum3);
    padding: 10px 15px;
    pointer-events: initial;
  }
.alert-message-lYz9C button {
      display: inline-flex;
      font-family: inherit;
      font-size: 14px;
      padding: 0;
      border: none;
      cursor: pointer;
      fill: var(--link-color);
      color: var(--link-color);
      text-decoration: none;
      background-color: transparent;
    }
.alert-message-lYz9C button:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
    background-color: transparent;
    border: none;
  }
.alert-message-lYz9C button {

      color: var(--tertiary);
}
.alert-message-lYz9C .message-h9FwZ {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-between;
    }
.alert-message-lYz9C .message-h9FwZ .close-16OC3 {
        display: inline-block;
        align-self: start;
        margin-left: 10px;
        font-size: 21px;
        font-weight: 200;
        line-height: 1;
        cursor: pointer;
      }
.alert-message-lYz9C .details-aR7LX {
      display: inline-block;
      font-size: 11px;
      padding: 5px 0;
      text-decoration: underline;
      cursor: pointer;
    }
.alert-message-lYz9C .error-details-2kddS {
      font-size: 12px;
    }
.alert-message-lYz9C .request-id-RKxZ0 {
      font-size: 12px;
    }
.alert-message-lYz9C.error-1m9dt {
      background-color: var(--danger);
    }
.alert-message-lYz9C.warning-336Fd {
      background-color: var(--yellow3);
    }
.alert-message-lYz9C.info-3fDjK {
      background-color: var(--core-blue4);
    }
.alert-message-lYz9C.success-dZuvJ {
      background-color: var(--success);
    }
