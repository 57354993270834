.container-24Mha .subtitle-3TAU8 {
      color: var(--text-color);
    }
.container-24Mha table tr .open-icon-2mPOu {
          visibility: hidden;
          margin-left: 5px;
        }
.container-24Mha table tr a {
          color: var(--steel1);
          display: block;
        }
.container-24Mha table tr:hover a {
            color: var(--primary);
          }
.container-24Mha table tr:hover .open-icon-2mPOu {
            visibility: visible;
          }
