div.modal-1BoXO {
    max-width: 600px;
    min-width: 500px;
    overflow-y: auto;
  }
@media (max-width: 767px) {
div.modal-1BoXO {
    min-height: 100vh;
    min-width: 100vw;
    border: none;
  }

    div.modal-1BoXO .header-3hkIx {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    div.modal-1BoXO .body-1PITw {
      border: none;
    }

    div.modal-1BoXO .footer-2DzQt {
      border: none;
    }
  }
div.modal-1BoXO .title-2aP6z {
    margin-top: 0;
    margin-bottom: 0;
  }
div.modal-1BoXO .footer-2DzQt {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: center;
  }
div.modal-1BoXO .footer-2DzQt > div {
      display: flex;
    }
div.modal-1BoXO .footer-2DzQt > p {
      width: 100%;
      color: var(--steel2);
    }
div.modal-1BoXO .footer-2DzQt button,
    div.modal-1BoXO .footer-2DzQt a {
      display: flex;
      align-items: center;
    }
div.modal-1BoXO .header-3hkIx > span {
      display: flex;
      align-items: center;
    }
div.modal-1BoXO {

    min-width: 500px;
}
