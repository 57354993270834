div.modal-4vret {
    max-width: 600px;
    min-width: 500px;
    overflow-y: auto;
  }
@media (max-width: 767px) {
div.modal-4vret {
    min-height: 100vh;
    min-width: 100vw;
    border: none;
  }

    div.modal-4vret .header-28TXM {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    div.modal-4vret .body-1QVuY {
      border: none;
    }

    div.modal-4vret .footer-25yTG {
      border: none;
    }
  }
div.modal-4vret .title-3xxly {
    margin-top: 0;
    margin-bottom: 0;
  }
div.modal-4vret .footer-25yTG {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: center;
  }
div.modal-4vret .footer-25yTG > div {
      display: flex;
    }
div.modal-4vret .footer-25yTG > p {
      width: 100%;
      color: var(--steel2);
    }
div.modal-4vret .footer-25yTG button,
    div.modal-4vret .footer-25yTG a {
      display: flex;
      align-items: center;
    }
div.modal-4vret .header-28TXM > span {
      display: flex;
      align-items: center;
    }
