.container-2M94L .file-uploader-276-a {
      background-color: var(--tertiary);
    }
    .container-2M94L .error-msg-mTecL {
      color: var(--danger);
      align-items: center;
      display: flex;
    }
    .container-2M94L .error-msg-mTecL svg {
        margin: 5px 5px 0 0;
        fill: var(--danger);
      }
