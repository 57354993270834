.assets-limit-warning-38vvG {
    padding: 15px;
    color: #b88900;
    background-color: #f4eedd;
    display: flex;
    align-items: center;
  }
.assets-limit-warning-38vvG .text-3LKhg {
      vertical-align: middle;
      flex-grow: 1;
    }
.assets-limit-warning-38vvG svg {
      vertical-align: middle;
      fill: #b88900;
      margin: 0 10px;
      margin-left: 15px;
    }
.assets-limit-warning-38vvG .close-warning-28bbS > span {
        cursor: pointer;
      }
.assets-limit-warning-38vvG .close-warning-28bbS > span svg {
          vertical-align: middle;
          fill: #b88900;
          margin: 0 10px;
        }
.assets-limit-warning-38vvG .close-warning-28bbS span:hover svg, .assets-limit-warning-38vvG .close-warning-28bbS span:focus svg {
          fill: #856300;
        }
