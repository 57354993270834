button.new-search-Ee_ac {
    display: flex;
    align-items: center;
    margin: 20px auto;
    overflow: hidden;
    max-width: calc(100% - 20px);
  }

    button.new-search-Ee_ac .button-children {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    button.new-search-Ee_ac .anypoint-icon.icon-OI-Cj {
      margin-left: 5px;
    }
