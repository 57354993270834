/* This is repeated in createPage, refactor to a more general place */
.error-3DUow input:focus:hover,
      .error-3DUow input:focus {
        border-left-color: var(--danger);
        border-right-color: var(--danger);
      }
.edit-icon-1zI5Z {
    align-self: center;
    margin-left: 5px;
    display: flex;
  }
.action-1P7Ve {
    margin-left: 10px;
    cursor: pointer;
  }
.action-1P7Ve:hover,
    .action-1P7Ve:focus {
      fill: var(--primary);
    }
.title-7GDoe {
    display: flex;
    align-items: center;
    max-width: 85%;
  }
.title-7GDoe .name-2GXKY {
      display: inline-flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-left: 2px;
      padding: 5px 0;
    }
.title-7GDoe .name-2GXKY.editable-1PI5h {
        cursor: pointer;
      }
.title-7GDoe .name-2GXKY.editable-1PI5h .edit-icon-1zI5Z {
          margin-right: 10px;
        }
.title-7GDoe .name-2GXKY.editable-1PI5h .edit-icon-1zI5Z:hover {
            fill: var(--primary);
          }
.title-7GDoe .name-2GXKY.editable-1PI5h:focus .edit-icon-1zI5Z {
            fill: var(--primary);
          }
.title-7GDoe .name-2GXKY h1 {
        overflow-wrap: break-word;
        word-break: break-all;
        word-break: break-word;

        margin: 0;
      }
.title-7GDoe .name-2GXKY.disabled-bcRZa h1 {
        color: var(--aluminum4);
      }
.title-7GDoe .text-field-1yE3m input {
        font-family: var(--lume-g-font-family);
        font-style: normal;
        font-weight: 200;

        color: var(--text-color);
        font-size: 30px;
        padding: 2px 10px;
        margin: 3px 0;
      }
