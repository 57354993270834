@media (max-width: 767px) {
    .modal-S9v1m {
      min-width: none;
      padding: 30px;
    }
  }
@media (min-width: 768px) {
    .modal-S9v1m {
      min-width: 440px;
      padding: 40px;
    }
  }
.close-button-11G7o {
    position: absolute;
    top: -15px;
    right: -14.5px;
    z-index: 1;
    padding: 0;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    border-radius: 50%;
  }
.close-button-11G7o span:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }
.close-button-11G7o svg:first-of-type {
      margin-top: 0;
    }
.js-focus-visible .link-2db9J.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
.separator-37A4p {
    margin-bottom: 20px;
  }
.mule-icon-ACuAy {
    position: absolute;
    left: 8px;
    top: 8px;
  }
.login-button-SWcSo {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  }
.login-button-SWcSo > span:nth-of-type(1) {
      position: static;
      position: initial;
    }
.login-button-SWcSo {

    position: relative;
}
