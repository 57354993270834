.empty-asset-3sUqC {
    padding: 90px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
.empty-asset-3sUqC .icon-17nMB svg {
      fill: var(--aluminum3);
    }
.empty-asset-3sUqC .message-1B42E {
      overflow-wrap: break-word;
      word-break: break-all;
      word-break: break-word;

      max-width: 100%;
      margin: 0;
    }
.empty-asset-3sUqC .action-edit-3Dyjx {
      margin-top: 45px;
      display: flex;
    }
.empty-asset-3sUqC .action-edit-3Dyjx > span {
        display: flex;
        align-items: center;
      }
.js-focus-visible .empty-asset-3sUqC .action-edit-3Dyjx.focus-visible {
    /* stylelint-disable-next-line declaration-no-important */
    outline: var(--core-blue3) auto 5px !important;
  }
