.actions-eSRdz {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary);
    height: 60px;
    padding: 0 4px;
  }
.actions-eSRdz .action-1TIHP {
    display: flex;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    padding: 5px 10px;
    color: var(--aluminum1);
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
  }
.actions-eSRdz .action-1TIHP svg {
    vertical-align: middle;
    fill: var(--aluminum1);
  }
.actions-eSRdz .action-1TIHP:hover,
  .actions-eSRdz .action-1TIHP.focus-visible {
    background-color: var(--robust-blue4);
    color: var(--aluminum1);
    border-radius: 4px;
  }
.actions-eSRdz .action-1TIHP:hover svg, .actions-eSRdz .action-1TIHP.focus-visible svg {
      fill: var(--aluminum1);
    }
.actions-eSRdz .action-1TIHP:focus {
    outline: none;
  }
.actions-eSRdz .action-1TIHP .label-1yCrY {
    margin-left: 5px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
  }
.actions-eSRdz .action-1TIHP[disabled],
  .actions-eSRdz .action-1TIHP.disabled-3qC7J {
    pointer-events: none;
    color: var(--aluminum3);
    opacity: 0.5;
  }
.actions-eSRdz .action-1TIHP[disabled] svg, .actions-eSRdz .action-1TIHP.disabled-3qC7J svg {
      fill: var(--aluminum3);
    }
.actions-eSRdz {

    padding: 0 20px;
}
.actions-eSRdz .action-1TIHP {
      font-family: var(--lume-g-font-family);
      font-style: normal;
      font-weight: 400;
    }
