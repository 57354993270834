.container-uLrU- {
    margin: 0 -20px;
  }
@media (min-width: 992px) {
.container-uLrU- {
      display: flex;
      height: calc(100% - 60px)
  }
    }
.container-uLrU- .main-1T7NT {
      width: 100%;
      padding: 0 20px;
    }
.container-uLrU- .main-1T7NT .page-name-bar-2CZBy {
        display: flex;
        justify-content: space-between;
      }
.container-uLrU- .main-1T7NT .editor-container-1lh0I {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
.container-uLrU- .main-1T7NT .editor-container-1lh0I .editor-11S-j {
          height: 100%;
          margin: 0 -20px;
        }
.container-uLrU- .main-1T7NT .spinner-3nY1T {
        display: flex;
        justify-content: center;
        padding: 13em 0;
      }
