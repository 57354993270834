.draggable-tree-3trOn .tree-element-WE8zL {
      font-size: 14px;
    }

      .draggable-tree-3trOn .tree-element-WE8zL .page-1Awyx {
        padding: 10px 15px 10px 5px;
      }

      .draggable-tree-3trOn .tree-element-WE8zL .page-1Awyx .page-move-2paiM {
          border: 2px dotted transparent;
          border-top: 0;
          border-bottom: 0;
          width: 5px;
          height: 14px;
        }

      .draggable-tree-3trOn .tree-element-WE8zL .page-1Awyx .hidden-e2AHr {
          visibility: hidden;
        }

      .draggable-tree-3trOn .tree-element-WE8zL .page-1Awyx .label-_bnqc {
          padding-left: 5px;
        }

      .draggable-tree-3trOn .tree-element-WE8zL .page-1Awyx:hover .page-move-2paiM {
            border: 2px dotted var(--aluminum5);
            cursor: grab;
          }
    .draggable-tree-3trOn .dragged-element-1kStk {
      background-color: var(--aluminum3);
    }
